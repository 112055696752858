/* reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}
input::placeholder {
  color: #c5c5c5;
}

input::-webkit-input-placeholder {
  color: #c5c5c5;
}

input:-ms-input-placeholder {
  color: #c5c5c5;
}

/* layout & common */
.admin-layout {
  font-family: "Pretendard", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  color: #333;
}

.admin-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 12px 48px;
  height: 72px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.admin-contents-layout {
  display: flex;
  flex: 1;
  margin-top: 72px;
}

.admin-divider {
  width: 100%;
  border-top: 0.6px solid #e0e0e0;
  margin-bottom: 10px;
}

.admin-login-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 210px;
}

/* login */
.admin-login-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 602px;
}

.admin-login-inputWrap {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox .checkmark {
  height: 20px;
  width: 20px;
  background-color: #ccc;
  border-radius: 4px;
  margin-right: 8px;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #005bac;
  border: 2px solid #8fb6ff;
  box-sizing: border-box;
  background-image: url("./assets/images/ic_admin_check.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + .checkmark::after {
  display: block;
}

.custom-checkbox .checkmark::after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.admin-login-label {
  opacity: 0.7;
}

.admin-login-input {
  height: 48px;
  border-radius: 6px;
  border: none;
  border-bottom: 1px solid #c1c7cd;
  background-color: #f5f6fa;
  padding: 14px;
  box-sizing: border-box;
}

.admin-login-btn {
  width: 602px;
  height: 48px;
  background-color: #005bac;
  border: 2px solid #8fb6ff;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.admin-eyes {
  position: absolute;
  top: 34px;
  right: 14px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* side-bar */
.admin-tab-container {
  position: fixed;
  width: 220px;
}

.admin-side-bar {
  padding: 24px 20px 100px 20px;
  border-right: 1px solid #dfdfdf;
  min-width: 220px;
}

.admin-tab-item {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 14px;
  padding: 14px 16px;
}

.admin-tab-item:hover {
  background-color: #005bac;
  border-radius: 6px;
}

.admin-tab-active {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 14px;
  padding: 14px 16px;
  border-radius: 6px;
  background-color: #005bac;
}

.admin-icon-inActive {
  fill: #333333;
  width: 24px;
  height: 24px;
}

.admin-icon-active {
  fill: #fff;
  width: 24px;
  height: 24px;
}

.admin-text-inActive {
  color: #000;
}

.admin-text-active {
  color: #fff;
}

.admin-tab-item:hover .admin-icon-inActive,
.admin-tab-active:hover .admin-icon-active {
  fill: #fff; /* hover 시 아이콘 색상 */
}

.admin-tab-item:hover .admin-text-inActive,
.admin-tab-active:hover .admin-text-active {
  color: #fff; /* hover 시 텍스트 색상 */
}

.admin-common-container {
  padding: 30px 109px 50px 30px;
  width: calc(100% - 238px);
  background-color: #f5f6fa;
  overflow-x: hidden;
}

.admin-common-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* peopleManagement */
.admin-people-position-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 33px;
}

.admin-people-item {
  padding: 16px;
  background-color: white;
  border: none;
  cursor: pointer;
  border-radius: 14px;
  height: 100px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  color: #333;
}

.admin-people-item-active {
  padding: 16px;
  background-color: #005bac;
  border: none;
  cursor: pointer;
  border-radius: 14px;
  height: 100px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  color: #fff;
}

.admin-postion-text {
  opacity: 0.7;
}

.admin-position-num {
  text-align: end;
  font-size: 28px;
  font-weight: 700;
}

.admin-people-control {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.admin-control-btnwrap {
  display: flex;
  gap: 20px;
}

.admin-control-btn {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background-color: #005bac;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 120px;
}

.admin-control-unactivebtn {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background-color: #ddd;
  border: none;
  border-radius: 6px;
  width: 120px;
}

.admin-control-icon {
  fill: #fff;
  width: 24px;
  height: 24px;
}

.admin-control-activeicon {
  fill: #005bac;
  width: 24px;
  height: 24px;
}

.admin-control-Activebtn {
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  color: #005bac;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 120px;
  border: 2px solid #005bac;
}

.admin-people-searchwrap {
  display: flex;
  align-items: center;
  gap: 16px;
}

.admin-people-searchbox {
  padding: 10px 0 10px 14px;
  border: 1px solid #d9d9d9;
  font-size: 16px;
  width: 233px;
  box-sizing: border-box;
}

.admin-people-table {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  background-color: #fff;
  table-layout: auto;
}

.admin-table-head th {
  padding: 20px 0;
}

.admin-table-body {
  text-align: center;
}

.admin-table-body td {
  padding: 14px 0;
  vertical-align: middle;
  border-top: 1px solid #d5d5d5;
}

.admin-table-imageWrap {
  width: 80px;
  height: 100px;
}

.admin-table-img {
  width: 80px;
  height: 100px;
  object-fit: cover;
}

.admin-table-edit,
.admin-table-del {
  padding: 10px 16px;
  cursor: pointer;
}

.admin-table-edit {
  background-color: #005bac;
  border-radius: 6px;
  border: none;
  color: #fff;
}

.admin-table-del {
  background-color: #ededed;
  border-radius: 6px;
  border: none;
  color: #999999;
}

.admin-table-body td:nth-child(1) {
  width: 10%;
}

.admin-table-body td:nth-child(3) {
  width: 20%;
}

.admin-table-body td:nth-child(6) {
  width: 20%;
}

/* news-letter */
.admin-writing-wrapper {
  gap: 30px;
  display: flex;
  /* height: 100%; */
}

.admin-writing-tab {
  width: 286px;
  padding: 24px;
  border-radius: 14px;
  border: 0.3px solid #b9b9b9;
  background-color: #fff;
  box-sizing: border-box;
  height: auto;
}

.admin-news-category {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 238px;
}

.admin-news-category li {
  padding: 12px 14px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.admin-category-activeItem,
.admin-category-item:hover {
  background-color: #005bac;
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
}

.admin-news-contents {
  width: 1234px;
  background-color: white;
  border-radius: 14px;
  padding: 24px 24px 60px;
  box-sizing: border-box;
}

.admin-news-btnwrap {
  display: flex;
  justify-content: space-between;
}

.admin-news-itemWrap {
  margin-top: 24px;
  border-top: 1px solid #d5d5d5;
}

.admin-news-item {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #d5d5d5;
}

.admin-creator {
  flex: 2;
}

.admin-title {
  flex: 8;
  margin-right: 10px;
}

.admin-category {
  flex: 3;
}

.admin-date {
  flex: 2;
  color: gray;
}

.admin-news-iconWrap {
  display: flex;
  gap: 10px;
}

.admin-news-icon {
  padding: 6px;
  background-color: #f5f6fa;
  border-radius: 4px;
  border: 0.6px solid #d5d5d5;
  cursor: pointer;
}

.admin-page-warp {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.admin-page-item {
  display: flex;
}

.admin-page-num {
  color: #999;
  font-weight: 700;
  cursor: pointer;
}

.admin-page-activeItem {
  color: #1676bc;
  font-weight: 700;
  cursor: pointer;
}

.admin-page-divide {
  width: 2px;
  height: 18px;
  background-color: #d9d9d9;
  margin: 0 14px;
}

.admin-prev {
  margin-right: 14px;
  cursor: pointer;
}

.admin-next {
  margin-left: 14px;
  cursor: pointer;
}

.admin-work-contents {
  width: 1234px;
  background-color: white;
  padding: 60px 60px 40px;
  box-sizing: border-box;
}

.admin-photo {
  cursor: pointer;
  width: 80px;
  height: 80px;
}

.admin-photo-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.admin-photo-text {
  font-size: 14px;
  font-weight: 700;
  color: #005bac;
  cursor: pointer;
}

.admin-word-textarea {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
}

.admin-word-textarea span {
  font-size: 14px;
  font-weight: 500;
}

.admin-word-textarea textarea {
  background-color: #f5f6fa;
  border: 0.6px solid #d5d5d5;
  resize: none;
  height: 248px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
}

.admin-work-inputWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.admin-work-inputWrap span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.admin-number-inputWrap {
  display: flex;
  align-items: center;
}

.admin-number-inputWrap span {
  margin: 0 6px;
}

.admin-number-input {
  padding: 6px 16px;
  width: 69px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  background-color: #f5f6fa;
  box-sizing: border-box;
}

.admin-text-input {
  padding: 6px 16px;
  border-radius: 4px;
  height: 36px;
  border: 1px solid #d5d5d5;
  background-color: #f5f6fa;
  box-sizing: border-box;
  flex: 1;
}

.admin-word-exam {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
}

.admin-word-exam span {
  font-size: 14px;
  font-weight: 500;
}

.admin-word-exam input {
  background-color: #f5f6fa;
  border: 0.6px solid #d5d5d5;
  resize: none;
  height: 36px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
}

.admin-register-container {
  padding: 30px 109px 50px 30px;
  background-color: #f5f6fa;
  overflow-x: hidden;
}

.admin-people-registerWrap {
  width: 1550px;
  background-color: white;
  padding: 60px 60px 40px;
}

.admin-register-inputCon {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.admin-register-half {
  margin-top: 30px;
  width: 50%;
}

.admin-register-inputWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin-register-label {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
}

.admin-register-input {
  border-radius: 4px;
  border: 0.6px solid #d5d5d5;
  height: 52px;
  padding-left: 14px;
  background-color: #f5f6fa;
  font-size: 16px;
}

.admin-register-innerInputWrap,
.admin-register-innerDropdownWrap {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: end;
}

.admin-register-dropdownWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin-register-dropbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 16px;
  font-size: 16px;
  background-color: #f5f6fa;
  border: 0.6px solid #d5d5d5;
  border-radius: 4px;
  cursor: pointer;
}

.admin-dropList {
  border-bottom: 0.6px solid #d9d9d9;
  border-right: 0.6px solid #d9d9d9;
  border-left: 0.6px solid #d9d9d9;
  padding: 24px 8px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: absolute;
  width: 377px;
  box-sizing: border-box;
  background-color: white;
}

.admin-dropItem {
  display: flex;
  align-items: center;
}

.admin-custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.admin-custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.admin-custom-checkbox .admin-checkmark {
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  background-image: url("./assets/images/ic_admin_uncheck.svg");
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-right: 8px;
}

.admin-custom-checkbox input:checked + .admin-checkmark {
  background-color: #005bac;
  border: none;
  box-sizing: border-box;
  background-image: url("./assets/images/ic_admin_check.svg");
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.admin-custom-checkbox .admin-checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.admin-custom-checkbox input:checked + .admin-checkmark::after {
  display: block;
}

.admin-custom-checkbox .admin-checkmark::after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.admin-register-textarea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.admin-register-textarea span {
  font-size: 14px;
  font-weight: 500;
}

.admin-register-textarea textarea {
  background-color: #f5f6fa;
  border: 0.6px solid #d5d5d5;
  resize: none;
  height: 332px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
}

.admin-register-career,
.admin-register-exam,
.admin-register-education {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-register-career span,
.admin-register-exam span,
.admin-register-education span {
  font-size: 14px;
  font-weight: 500;
}

.admin-register-career,
.admin-register-exam {
  margin-top: 40px;
}

.admin-year-btnWrap {
  width: 159px;
}

.admin-year-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 6px 16px;
  font-size: 16px;
  background-color: #f5f6fa;
  border: 0.6px solid #d5d5d5;
  border-radius: 4px;
  cursor: pointer;
}

.admin-year-list {
  width: 159px;
  height: 300px;
  overflow-y: auto;
  border-bottom: 0.6px solid #d9d9d9;
  border-right: 0.6px solid #d9d9d9;
  border-left: 0.6px solid #d9d9d9;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: absolute;
  box-sizing: border-box;
  background-color: white;
}

.admin-year-list li {
  cursor: pointer;
  padding: 8px 16px;
}

.admin-year-list li:hover {
  background-color: #dfdfdf;
  border-radius: 4px;
}

.admin-year-btnBas {
  color: #c5c5c5;
}

.admin-register-btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  text-align: center;
  gap: 28px;
}

.admin-register-savebtn {
  padding: 16px 121px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  background-color: #005bac;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.admin-logout-btn {
  border: none;
  background-color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.admin-logout-btnWrapDivider {
  border-top: 0.6px solid #d5d5d5;
  width: 224px;
  height: 200px;
  display: flex;
  position: fixed;
  bottom: 0;
}

.admin-logout-btnWrap {
  display: flex;
  padding-left: 16px;
  align-items: center;
  padding-bottom: 100px;
  gap: 16px;
}

.admin-post-registerCon {
  background-color: white;
  padding: 40px 60px 40px;
  border-radius: 14px;
}

.admin-post-selBtnWrap {
  width: 141px;
  height: 28px;
  margin-top: 20px;
}

.admin-post-selBtnWrap p {
  font-size: 14px;
}

.admin-post-selBtn {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  color: #c5c5c5;
  font-weight: 500;
  font-size: 14px;
  margin-top: 6px;
  cursor: pointer;
}

.admin-post-selBtnText {
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  display: block;
}

.admin-post-btnLine {
  display: flex;
  gap: 8px;
}

.admin-post-inputWrap {
  flex: 1;
  margin-top: 20px;
}

.admin-post-inputWrap p {
  font-size: 14px;
}

.admin-post-inputWrap input {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  font-weight: 500;
  margin-top: 6px;
  padding-left: 6px;
  box-sizing: border-box;
}

.admin-post-dropList {
  border-bottom: 0.6px solid #d9d9d9;
  border-right: 0.6px solid #d9d9d9;
  border-left: 0.6px solid #d9d9d9;
  padding: 24px 8px;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 14px;
  position: absolute;
  width: 141px;
  box-sizing: border-box;
  background-color: white;
  z-index: 10;
}

.admin-post-dropItem label {
  font-size: 15px;
}

.admin-post-dropItem p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
}

.admin-post-dropItemTemp {
  margin-top: 10px;
}

.admin-post-dropItemTemp p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
}

.admin-media-emtpyWrap {
  width: 749px;
  height: 300px;
}

.admin-media-inputWrap {
  width: 185px;
  height: 28px;
}

.admin-media-inputWrap p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.admin-media-inputWrap input {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  font-weight: 500;
  margin-top: 6px;
  padding-left: 6px;
  box-sizing: border-box;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin-left: 45px;
  cursor: pointer;
}

.admin-media-inputWrap input[type="date"] {
  letter-spacing: -1.5px;
  outline: none;
}

.admin-media-inputWrap input:disabled {
  background-color: #e4e4e4;
  border: 1px solid #d5d5d5;
  color: #333333;
}

.admin-media-dropList {
  border-bottom: 0.6px solid #d9d9d9;
  border-right: 0.6px solid #d9d9d9;
  border-left: 0.6px solid #d9d9d9;
  padding: 24px 8px;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 14px;
  position: absolute;
  width: 185px;
  box-sizing: border-box;
  background-color: white;
  z-index: 10;
}

.admin-media-dropItem label {
  font-size: 15px;
}

.admin-media-dropItem p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}

.admin-media-dropItemTemp {
  margin-top: 10px;
}
