.header-mob {
  display: none;
}

@media (max-width: 1800px) {
  .member-profile-page .save {
    margin: 0 100px;
  }
  .member-profile-page .title-section {
    margin: 100px;
  }
  .member-profile-page .profile-main {
    margin: 40px 100px 0px 100px;
  }

  .member-profile-page .sub-menu {
    margin: 0px 100px 50px 100px;
  }

  .member-profile-page .fold-section {
    margin: 0 100px;
  }
}

@media (max-width: 1550px) {
  .header .logo {
    margin-left: 100px;
  }
  .header .icons {
    display: flex;
    gap: 24px;
    margin-right: 200px;
  }
}

@media (max-width: 1403px) {
  .App {
    font-size: 0.5em;
  }

  .header {
    display: none;
  }

  .side-button-container {
    display: none;
  }

  .header-mob {
    display: block;
    background-color: #fff;
    z-index: 1000;
    position: relative;
  }

  .footer {
    background-color: #697077;
    padding: 40px 20px;
  }

  .footer-content .section2 {
    font-size: 14px;
    line-height: 14px;
  }

  /* 팝업 */

  .popup-content {
    left: 50%;
  }

  @media (max-width: 480px) {
    .popup-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .popup-content {
      width: calc(100% - 40px);
    }

    .popup-img {
      width: 100%;
    }

    .popup-content {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
    }

    .popup-btn-wrap {
      display: flex;
    }

    .popup-btn {
      width: 50%;
      font-size: 14px;
      height: 40px;
    }
  }

  .footer-content .section2 .footer-links .bar {
    visibility: hidden;
  }

  .footer-content .section2 .footer-links {
    display: flex;
    flex-direction: column;
  }

  /* 랜딩페이지 */

  .landing-page {
    padding-top: 0;
  }

  .landing-page .main-section {
    height: 100vh;
  }

  .landing-page .main-section .search-bar {
    width: 90%;
    justify-content: space-between;
    padding: 10px 20px;
  }

  .landing-page .main-section .search-bar .search-input {
    font-size: 13px;
    flex-grow: 0;
  }

  .landing-page .main-section .main-title {
    font-size: 20px;
    line-height: 20px;
  }

  .landing-page .main-section .main-subtitle {
    font-size: 26px;
    margin-top: 14px;
    line-height: 33.8px;
  }

  /* 랜딩페이지 뉴스섹션 */

  .landing-page .news-section {
    margin: 50px 20px;
    height: auto;
  }

  .landing-page .news-section .theme-select {
    margin: 5%;
  }

  .landing-page .theme-select button {
    min-width: 100px;
    font-size: 16px;
  }

  .landing-page .news-section > .content {
    flex-direction: column;
    height: auto;
    text-align: center;
    align-items: center;
  }

  .landing-page .news-section .content .img-section-wrap {
    height: 258px;
    padding-left: 103px;
    background: url("../src/assets/images/SmallEllipse.png") no-repeat center
      left;
  }

  .landing-page .news-section .content .img-section {
    position: relative;
    display: block;
    min-width: 232px;
    min-height: 232px;
    width: 232px;
    height: 232px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #f2f2f2;
    margin-right: 0;
  }

  .landing-page .news-section .content .img-section img {
    min-width: 232px;
    min-height: 232px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .landing-page .news-section .content .text-section {
    text-align: left;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    flex: 1;
  }

  .landing-page .news-section .content .text-section .text-wrap {
    opacity: 1;
    transition: opacity 0.5s;
    width: 100%;
    font-size: 18px;
  }

  .landing-page .news-section .content .text-section .nav {
    width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .landing-page .news-section .content .text-section .nav span {
    font-size: 16px;
    color: #1676bc;
    font-weight: 500;
    line-height: 16px;
  }

  .landing-page .news-section .content .text-section {
    width: 100%;
    padding-left: 0;
    max-width: none;
    max-height: none;
  }

  .landing-page .news-section .content .text-section .text-wrap {
    opacity: 1;
    max-width: none;
    min-height: 200px;
    font-size: 18px;
  }

  .landing-page .news-section .content .text-section .title {
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 20px;
    line-height: 25px;
  }

  .landing-page .news-section .content .text-section .content {
    -webkit-line-clamp: 5;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 25px;
  }

  .landing-page .news-section .content .text-section .more {
    padding: 0;
    font-size: 14px;
  }

  /* 랜딩페이지 뉴스레터섹션 */

  .landing-page .newsletter-section {
    margin: 50px 20px;
  }

  .landing-page .newsletter-section .title {
    margin: 0px;
  }

  .landing-page .newsletter-section .swiper-container {
    margin: 50px 0;
  }

  .landing-page .newsletter-section .nav-button {
    display: none !important;
  }

  .landing-page .newsletter-section .newsletter-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    max-width: 338px;
    min-height: 150px;
    max-height: 262px;
  }

  .landing-page .newsletter-section .newsletter-content .mark {
    text-align: left;
    height: 20px;
    align-items: center;
    display: flex;
  }

  .landing-page .newsletter-section .newsletter-content .mark span {
    background-color: #1676bc;
    color: white;
    padding: 8px;
    font-size: 1.15em;
    align-items: center;
    height: 10px;
    font-weight: bold;
  }

  .landing-page .newsletter-section .newsletter-content .title {
    height: 150px;
    font-weight: bold;
    text-align: left;
    font-size: 14px;
    margin: 10px 0;
    line-height: 1.5em;
  }

  .landing-page .newsletter-section .newsletter-content .title span {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .landing-page .newsletter-section .newsletter-content .bottom {
    height: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 1.25em;
    color: #888;
    align-items: center;
    margin-top: auto;
  }

  /* 랜딩페이지 구성원 */

  .landing-page .hired-section {
    margin: 50px 20px;
  }

  .landing-page .news-section .title .more {
    padding-left: 10px;
  }

  .landing-page .newsletter-section .title .more {
    padding-left: 10px;
  }

  .landing-page .hired-section .title .more {
    padding-left: 10px;
  }

  .landing-page .hired-section .content-wrap {
    flex-direction: column;
  }

  .landing-page .hired-section .content-wrap .section01 {
    flex-basis: 0;
    flex-shrink: 0;
    margin-right: 0;
    position: relative;
    width: 100%;
  }

  .landing-page .hired-section .content-wrap .section01 .img-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .landing-page .hired-section .content-wrap .section01 .img-wrap img {
    max-height: 220px;
    width: 153px;
  }

  .landing-page .hired-section .content-wrap .section01 .text-wrap {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 5px;
    padding: 15px 0px 0px 0px;
  }

  .landing-page .hired-section .content-wrap .section01 .text-wrap .job {
    font-size: 16px;
    line-height: 16px;
    color: #999999;
  }

  .landing-page .hired-section .content-wrap .section01 .text-wrap .name {
    font-size: 32px;
    line-height: 32px;
    color: #333333;
    font-weight: 400;
  }

  .landing-page .hired-section .content-wrap .section01 .text-wrap .email {
    width: 100%;
    font-size: 12px;
    color: #999999;
    margin: 5px 0px 0px 0px;
  }

  .landing-page .hired-section .content-wrap .section01 .text-wrap .class-wrap {
    max-width: 191px;
  }

  .landing-page .hired-section .content-wrap .section01 .text-wrap .class {
    font-size: 14px;
    line-height: 14px;
    color: #666;
    border: 1px solid #d9d9d9;
    text-align: start;
    width: fit-content;
    padding: 2px 4px;
  }

  .landing-page .hired-section .content-wrap .section01 .nav-button.prev {
    left: 50px;
  }

  .landing-page .hired-section .content-wrap .section01 .nav-button.next {
    right: 50px;
  }

  .landing-page .hired-section .swiper-container {
    max-width: none;
    width: 100%;
    height: 300px;
    margin-top: 20px;
    flex-basis: 70% !important;
  }

  .landing-page .hired-section .content {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-bottom: 30px;
    width: 215px;
  }

  .landing-page .hired-section .content .img-wrap img {
    filter: grayscale(0);
    max-height: 210px;
    width: 137px;
  }

  .landing-page .hired-section .content .text-wrap {
    text-align: left;
    padding-left: 15px;
    margin-left: 0px;
  }

  .landing-page .hired-section .content .text-wrap .job {
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 16px;
    margin-left: 5px;
  }

  .landing-page .hired-section .content .text-wrap .email {
    font-size: 12px;
    color: #999;
    line-height: 12px;
  }

  .landing-page .hired-section .content .text-wrap .name {
    font-size: 20px;
    color: #333;
    font-weight: 400;
    margin: 10px 0px;
    line-height: 28px;
  }

  .landing-page .hired-section .content .text-wrap .class {
    font-size: 11px;
    line-height: 12px;
  }

  .landing-page .hired-section .content.active,
  .landing-page .hired-section .content:hover {
    transform: scale(1);
  }

  .landing-page .hired-section .content.active .text-wrap,
  .landing-page .hired-section .content:hover .text-wrap {
  }

  /* 랜딩페이지 맵 */
  .landing-page .lawvax-map-section {
    padding: 0;
    margin: 50px 20px;
  }

  .landing-page .lawvax-map-section .content .search-bar {
    padding: 10px 10px 10px 10px;
    width: 100%;
    max-width: 100%;
  }

  .landing-page .lawvax-map-section .content {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 50px;
    gap: 0px;
  }

  .landing-page .lawvax-map-section .content .text-section {
    flex: 40% 1;
    text-align: left;
    padding-right: 0px;
  }

  .landing-page .lawvax-map-section .content p img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .landing-page .lawvax-map-section .call img {
    height: 15px;
    padding-right: 10px;
  }

  .landing-page .lawvax-map-section .content .sub-wrap {
    padding: 0;
  }

  .landing-page .lawvax-map-section .content .sub-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .landing-page .lawvax-map-section .content .search-bar input {
    padding: 0 10px;
    font-size: 16px;
    line-height: 16px;
  }

  .landing-page .lawvax-map-section .content .search-bar img {
    width: 12px;
  }

  .landing-page .lawvax-map-section .content .gray {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
  }

  .landing-page .lawvax-map-section .content .sub-wrap .adress {
    font-size: 16px;
    line-height: 16px;
    color: #333;
  }

  .landing-page .lawvax-map-section .call span {
    font-size: 14px;
    line-height: 14px;
  }

  .landing-page .lawvax-map-section .traffic p {
    font-size: 16px;
    line-height: 16px;
  }

  .landing-page .lawvax-map-section .content .map-section {
    padding: 0;
    width: 100%;
  }

  .landing-page .lawvax-map-section .content .share {
    position: absolute;
    top: -50px;
    right: 0;
  }

  .landing-page .lawvax-map-section .content .share img {
    width: 12px;
    padding-right: 5px;
  }

  /* 검색 페이지 */

  .landing-search-page .process {
    margin: 10% 5%;
  }

  .landing-search-page .search-bar-wrap {
    padding-bottom: 60px;
    padding: 5%;
  }

  .landing-search-page .search-bar {
    padding: 5%;
    height: 1em;
  }

  .landing-search-page .members-section {
    padding: 5%;
  }

  .landing-search-page .members-section .members-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px 0 48px 0;
  }

  .landing-search-page .members-section .toggle-button::before,
  .landing-search-page .members-section .toggle-button::after {
    top: 50%;
    width: 30%;
  }

  .landing-search-page .members-section .members-wrap .lawyer-item img {
    max-width: 200px;
    min-width: 150px;
  }

  .landing-search-page .members-section .members-wrap .lawyer-item .p1 {
    font-size: 22px;
  }

  .landing-search-page .members-section .members-wrap .lawyer-item .p2 {
    font-size: 12px;
  }

  .landing-search-page .members-section .members-wrap .lawyer-item .email {
    font-size: 12px;
  }

  .landing-search-page .members-section .members-wrap .lawyer-item .p3 {
    font-size: 11px;
  }

  .landing-search-page .service-section {
    padding: 5%;
    background-color: #f9f9f9;
  }

  .landing-search-page .sub-title .more {
    padding-left: 10px;
  }

  .landing-search-page .sub-title p {
    font-size: 22px;
  }

  .landing-search-page .news-section01 {
    padding: 5%;
  }

  .landing-search-page .news-section01 .content .news-item .content {
    font-size: 14px;
  }

  .landing-search-page .news-section01 .content .news-item .date {
    font-size: 14px;
  }

  .landing-search-page .news-section02 {
    padding: 5%;
  }

  .landing-search-page .news-section02 .content .news-item .title {
    font-size: 16px;
  }

  .landing-search-page .news-section02 .content .news-item .wrap .company {
    font-size: 14px;
  }

  .landing-search-page .news-section03 {
    padding: 5%;
  }

  .landing-search-page .news-section03 .content .news-item .title {
    font-size: 16px;
  }

  .landing-search-page .news-section03 .content .news-item .content {
    font-size: 14px;
  }

  .landing-search-page .news-section03 .content .news-item .date {
    font-size: 14px;
  }

  .landing-search-page .newsletter-section {
    padding: 5%;
  }

  /* 소개 페이지 */

  .intro-page {
    display: block;
  }

  .intro-page .process,
  .intro-page .section01 {
    margin: 50px 20px 0px 20px;
    padding: 0;
    background-color: #fff;
  }

  .intro-page .p2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .intro-page .p3 {
    font-size: 15px;
    line-height: 25px;
    color: #111;
    word-break: keep-all;
  }

  .intro-page .process img {
    width: 15px;
  }

  .intro-page .section01 > div {
    display: block;
  }

  .intro-page .section01 img {
    max-width: 566px;
    width: 100%;
  }

  .intro-page .section01 h1 {
    font-size: 42px;
  }

  .intro-page .section02 {
    padding: 5px 0px;
    background-image: none;
    position: relative;
    height: auto;
  }

  .intro-page .section02 .text-wrap {
    padding: 10px 20px;
  }

  .intro-page .section02 .text-wrap .p2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .intro-page .section02 .p3 {
    font-size: 15px;
    line-height: 25px;
    color: #111;
  }

  .intro-page .section02 img {
    width: 100%;
  }

  .intro-page .section03 {
    margin: 50px 20px 50px 20px;
    display: block;
    height: auto;
    width: 95%;
  }

  .intro-page .section03 .img-wrap {
    padding: 0;
  }

  .intro-page .section03 .img-wrap {
    text-align: center;
    padding-left: 0px;
    width: 100%;
    height: auto;
  }

  .intro-page .section03 img {
    width: 100%;
  }

  .intro-page .section03 .text-wrap {
    text-align: left;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
  }

  .intro-page .section04 {
    margin: 50px 20px 50px 20px;
    display: block;
    width: 95%;
  }

  .intro-page .section04 .p2 {
    margin-bottom: 20px;
  }

  .intro-page .section04 .img-wrap {
    text-align: center;
    padding-left: 0px;
    width: 100%;
    height: auto;
  }

  .intro-page .section04 img {
    width: 100%;
  }

  .intro-page .section04 .text-wrap {
    margin-top: auto;
    width: 100%;
  }

  /* 업무 분야 */

  .services-page .process,
  .services-page > .title {
    margin: 10% 5%;
  }

  .services-page .content-wrap {
    border-top: 2px solid #d9d9d9;
    margin: 10% 5%;
  }

  .services-detail-page .content-wrap {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .services-detail-page .content-wrap .content-left .sub-title-ment p {
    font-size: 15px;
    line-height: 25px;
    color: #333;
    font-weight: 500;
    word-break: keep-all;
  }

  .services-detail-page .content-wrap .content-right {
    position: sticky;
    top: 0px;
    width: 100%;
    padding-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding-top: 0px;
    height: 30px;
    align-items: center;
    display: none;
  }

  .services-detail-page .content-wrap .content-left .main-field-of-work h1 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    color: #333;
  }

  .services-detail-page .content-wrap .content-right p {
    margin: 5px 10px;
  }

  .services-page .content-wrap > div .title {
    padding: 12px 20px;
  }

  .services-page .content-wrap > div .title span {
    font-size: 20px;
    line-height: 20px;
  }

  .services-page .content-wrap > div .title img {
    width: 25px;
  }

  .services-detail-page .process,
  .services-detail-page .title {
    margin: 10% 5%;
  }

  .services-detail-page .title p {
    line-height: 1em;
  }

  .services-detail-page .content-wrap .content-left {
    width: 100%;
  }

  .services-page .content-wrap > div > .fold-wrap {
    grid-template-columns: repeat(3, 1fr);
  }

  .services-detail-page .sub-title {
    margin: 5% 5% 0 5%;
  }

  .services-detail-page .sub-title p {
    font-size: 24px;
    line-height: 24px;
  }

  .services-detail-page .content-wrap .content-left .main-field-of-work li {
    display: flex;
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 400;
  }

  .services-detail-page .content-wrap .content-left .main-field-of-work span {
    padding-left: 0.5empx;
    font-size: 14px;
    line-height: 18.2px;
  }

  .services-detail-page .content-wrap .content-left .sub-title-ment {
    padding: 5%;
  }

  .services-detail-page
    .content-wrap
    .content-left
    .main-field-of-work
    .content {
    word-break: keep-all;
    font-size: 15px;
    line-height: 25px;
  }

  .services-detail-page .content-wrap .content-left .main-field-of-work p {
    margin: 12px 0;
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 400;
  }

  .services-detail-page .content-wrap .content-left .members h1 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    padding-bottom: 20px;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 1px solid #d9d9d9;
  }

  .services-detail-page
    .content-wrap
    .content-left
    .members
    .p1
    span:first-child {
    font-size: 22px;
    line-height: 22px;
  }

  .services-detail-page
    .content-wrap
    .content-left
    .members
    .p1
    span:last-child {
    font-size: 12px;
    line-height: 14px;
  }

  .services-detail-page .content-wrap .content-left .main-field-of-work ul {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0;
  }

  .services-detail-page .content-wrap .content-left .main-field-of-work,
  .services-detail-page .content-wrap .content-left .members,
  .services-detail-page .toggle-button-wrap,
  .services-detail-page .content-wrap .content-left .recent-news {
    padding: 5%;
  }

  .services-detail-page .content-wrap .content-left .members .members-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-items: center;
  }

  .services-detail-page .toggle-button {
    font-size: 14px;
    line-height: 14px;
  }

  .services-detail-page
    .content-wrap
    .content-left
    .members
    .members-grid
    .members-item {
    max-width: 157px;
    min-height: 350px;
  }

  .services-detail-page .toggle-button img {
    width: 14px;
    margin-left: 10px;
  }

  .services-detail-page .content-wrap .content-left .members .p1 {
    margin-top: 10px;
  }

  .services-detail-page .content-wrap .content-left .members .p2 {
    font-size: 12px;
    margin: 0;
  }

  .services-detail-page .content-wrap .content-left .members .p3 {
    font-size: 11px;
    margin: 0;
  }

  .services-detail-page .toggle-button::before,
  .services-detail-page .toggle-button::after {
    width: 40%;
  }

  .services-detail-page .recent-news h1 {
    line-height: 24px;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .services-detail-page .recent-news {
    margin: 5% 5%;
  }

  .services-detail-page .recent-news .newsletter-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    max-width: 338px;
    min-height: 150px;
    max-height: 262px;
  }

  .services-detail-page .recent-news .newsletter-content .mark {
    height: 20px;
  }

  .services-detail-page .recent-news .newsletter-content .mark span {
    font-size: 12px;
    line-height: 12px;
  }

  .services-detail-page .recent-news .newsletter-content .title {
    height: 56px;
    margin: 10px 0;
    line-height: 18.2px;
    font-size: 14px;
  }

  .services-detail-page .recent-news .bottom {
    font-size: 14px;
    line-height: 14px;
  }

  .services-detail-page .recent-news .nav-button {
    display: none;
  }

  .services-detail-page .recent-news p {
    font-size: 24px;
    line-height: 24px;
  }
  /* 구성원 */

  .members-page .search-bar {
    justify-content: space-between;
  }

  .members-page .search-bar input {
    font-size: 13px;
    flex: 0 0;
  }

  .member-profile-page .recent-news h1 {
    line-height: 16px;
    font-size: 16px;
  }

  .member-profile-page .recent-news .newsletter-content {
    padding: 10px;
    border: 2px solid #d9d9d9;
    flex-direction: column;
    max-width: 160px;
    min-height: 150px;
    max-height: 150px;
    box-sizing: border-box;
  }

  .member-profile-page .recent-news .title {
    height: 56px;
    font-weight: bold;
    text-align: left;
    font-size: 14px;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18.2px;
    color: #333333;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .member-profile-page .recent-news .mark span {
    background-color: #1676bc;
    color: white;
    padding: 8px;
    font-size: 12px;
    align-items: center;
    line-height: 12px;
    font-weight: bold;
  }

  .member-profile-page .recent-news .bottom .date {
    font-size: 14px;
    line-height: 14px;
    color: #999999;
    font-weight: 400;
  }

  .member-profile-page .recent-news {
    margin: 5% 5%;
  }

  .members-page .process,
  .members-page .title-section .sub-title {
    margin: 5%;
  }

  .members-page .lawyer-section {
    padding: 5%;
    padding-top: 15%;
    padding-bottom: 20vh;
  }

  .members-page .search-bar-wrap {
    padding: 5%;
  }

  .members-page .search-bar-wrap {
    position: relative;
    margin: 0 auto;
  }

  .members-page .search-section {
    background-color: #f9f9f9;
    padding: 0px 0;
  }

  .members-page .title-section .search-category-wrap button {
    border: 1px solid #1676bc;
    padding: 8px 12px;
  }

  .members-page .title-section .search-category-wrap button span {
    font-size: 16px;
    line-height: 16px;
  }

  .members-page .title-section .search-category-wrap .active {
    background-color: #1676bc;
    color: white;
  }

  .members-page .lawyer-section .lawyer-item .text-wrap {
    height: auto;
    padding-left: 20px;
  }

  .members-page .lawyer-section .lawyer-item .text-wrap .p1 {
    margin-top: 10px;
  }

  .members-page .lawyer-section .lawyer-item .text-wrap .p2 {
    margin: 5px 5px;
    font-size: 12px;
    line-height: 12px;
  }

  .members-page .lawyer-section .lawyer-item .text-wrap .p3 {
    font-size: 11px;
    line-height: 11px;
  }

  .member-search-page .process,
  .member-search-page .title-section .sub-title,
  .member-search-page .search-list-section,
  .member-search-page .search-bar-wrap {
    margin: 5%;
  }

  .members-page .lawyer-section .lawyer-item {
    filter: grayscale(0%);
    margin-bottom: 30px;
    min-height: 210px;
  }

  .member-search-page .search-list-section .search-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(200px, auto);
    justify-items: center;
    gap: 150px 0;
  }

  .members-page .lawyer-section .lawyer-item .text-wrap {
    opacity: 1;
    visibility: visible;
  }

  .members-page .lawyer-section .lawyer-item img {
    max-width: 80%;
  }

  .members-page .lawyer-section .toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px auto 50px auto;
    color: #999999;
    border: none;
    width: 100%;
    font-size: 1.5em;
  }

  .members-page .lawyer-section .toggle-button img {
    width: 20px;
    margin-left: 10px;
  }

  .members-page .lawyer-section .toggle-button::before,
  .members-page .lawyer-section .toggle-button::after {
    content: "";
    position: absolute;
    width: 30%;
  }

  /* 프로필 페이지 */

  .member-profile-page .title-section {
    margin: 5%;
  }

  .member-profile-page .profile-main .text-wrap .name .job {
    color: #999999;
    font-weight: 500;
    font-size: 14px;
    padding-left: 10px;
    line-height: 14px;
  }

  .member-profile-page .save {
    padding: 10px;
    position: absolute;
    top: 140px;
    right: 5%;
    margin: 0;
  }

  .member-profile-page .save span {
    cursor: pointer;
    color: #1676bc;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }

  .member-profile-page .save img {
    width: 15px;
  }

  .member-profile-page .profile-main {
    margin: 5%;
  }

  .member-profile-page .profile-main .content-wrap {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-left: 0;
    gap: 20px;
  }

  .member-profile-page .profile-main .text-wrap .sub-title01 span {
    padding-left: 10px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }

  .member-profile-page .profile-main .text-wrap .sub-content01 p {
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 10px;
  }

  .member-profile-page .profile-main .text-wrap .sub-title01 {
    margin-top: 30px;
  }

  .member-profile-page .profile-main .text-wrap .sub-title02 span {
    padding-left: 10px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }

  .member-profile-page .profile-main .text-wrap .sub-content02 {
    display: flex;
    gap: 5px;
    width: 100%;
    margin: 0;
  }

  .member-profile-page .profile-main .text-wrap .sub-content01 p {
    border: 1px solid black;
    padding: 5px 10px;
    white-space: nowrap;
    word-break: keep-all;
    margin: 0;
    font-size: 14px;
    line-height: 14px;
    margin-top: 5px;
  }

  .member-profile-page .profile-main .text-wrap .sub-content01 {
    margin-left: 0px;
  }

  .member-profile-page .profile-main .text-wrap .sub-content02 span {
    border: 1px solid black;
    padding: 5px 10px;
    white-space: nowrap;
    word-break: keep-all;
    margin: 0;
    font-size: 14px;
    line-height: 14px;
  }

  .member-profile-page .profile-main .img-wrap {
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
  }

  .member-profile-page .profile-main .img-wrap img {
    width: 230px;
  }

  .member-profile-page .profile-main .ment {
    padding: 20px 0;
    font-size: 15px;
    line-height: 25px;
    white-space: break-spaces;
    word-break: keep-all;
  }

  .member-profile-page .profile-main .text-wrap .name {
    font-size: 24px;
    line-height: 24px;
  }

  .member-profile-page .sub-menu {
    margin: 0;
    padding: 5%;
    padding-top: 0;
    top: 0px;
  }

  .member-profile-page .sub-menu .menu-item {
    font-size: 16px;
    line-height: 16px;
  }

  .member-profile-page .fold-section {
    margin: 5%;
    padding-bottom: 50px;
    font-size: 15px;
    line-height: 25px;
  }

  .member-profile-page .fold-section > div .title p {
    padding-left: 10px;
    font-size: 16px;
    line-height: 14px;
  }

  .member-profile-page .fold-section > div .title {
    border-left: #1676bc 5px solid;
  }

  .member-profile-page .fold-section .content-wrap {
    padding: 30px 0;
  }

  .member-profile-page .fold-section > div .content {
    margin: 0;
    display: flex;
    width: 100%;
  }

  .member-profile-page .fold-section > div .content > div {
    margin: 10px 0;
  }

  .member-profile-page .fold-section > div .content p {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 5px;
    width: 70%;
  }

  .member-profile-page .fold-section > div .content span {
    color: #111;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 5px;
  }

  .member-profile-page .fold-section > div .content .year-wrap {
    display: flex;
    align-items: start;
    position: relative;
    width: 30%;
    gap: 3px;
  }

  .member-profile-page .fold-section > div .content .year {
    display: inline-block;
    color: #999999;
    width: 100%;
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 500;
  }

  .member-profile-page .fold-section button img {
    width: 14px;
  }

  .member-profile-page .fold-section button {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }

  .member-profile-page .fold-section button::before,
  .member-profile-page .fold-section button::after {
    width: 38%;
  }

  .member-profile-page .info {
    margin: 5%;
    text-align: right;
  }

  .member-profile-page .info .phone {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .member-profile-page .info .phone span {
    font-size: 16px;
    line-height: 16px;
  }

  .member-profile-page .info .email {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .member-profile-page .info .phone > span {
    padding-right: 10px;
  }

  .member-profile-page .info .email span {
    font-size: 16px;
    line-height: 16px;
    padding-right: 10px;
  }

  .member-profile-page .recent-news {
    margin: 5% 5%;
  }

  .member-profile-page .recent-news .newsletter-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    max-width: 338px;
    min-height: 150px;
    max-height: 262px;
  }

  .member-profile-page .recent-news .newsletter-content .mark {
    height: 20px;
  }

  .member-profile-page .recent-news .newsletter-content .mark span {
    font-size: 12px;
    line-height: 12px;
  }

  .member-profile-page .recent-news .newsletter-content .title {
    height: 56px;
    margin: 10px 0;
    line-height: 18.2px;
    font-size: 14px;
  }

  .member-profile-page .recent-news .bottom {
    font-size: 14px;
    line-height: 14px;
  }

  .member-profile-page .recent-news .nav-button {
    display: none;
  }

  .member-profile-page .recent-news p {
    font-size: 24px;
    line-height: 24px;
  }

  /* 뉴스 페이지 */

  .news-page .process,
  .news-page .title {
    margin: 5%;
  }

  .news-page .search-bar {
    height: 44px;
    width: 335px;
    position: relative;
  }

  .news-page .search-input {
    height: 44px;
    width: 335px;
    box-sizing: border-box;
    font-size: 16px;
    padding: 0 44px 0 20px;
    border: 2px solid #999;
  }

  .news-page .search-bar .search-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 24px;
  }

  .news-page .theme-select button {
    padding-bottom: 7px;
  }

  .news-page .theme-select span {
    font-size: 16px;
    line-height: 16px;
  }

  .news-page .theme-select span.active {
    border-bottom: 2px solid black;
  }

  .news-page .theme-select {
    margin: 30px 0px 0px 0px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 23.5px;
  }

  .news-page .news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 10% 5%;
    gap: 10px;
  }

  .news-page .news-item {
    width: 100%;
  }

  .news-page .news-content {
    padding: 10px 20px;
    height: 110px;
  }

  .news-page .news-title {
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    line-height: 18.2px;
    height: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
  }

  .news-page .press-item label {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -5%;
  }

  .news-page .press-item h2 {
    font-size: 14px;
    line-height: 18.2px;
  }

  .news-page .news-date {
    font-size: 14px;
    line-height: 14px;
  }

  .news-page .press-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px 0px 50px 5px;
    place-items: center;
  }

  /* 뉴스 게시물 */
  .news-post-page .process {
    margin: 10% 5%;
  }

  .news-post-page .title {
    margin: 10% 5%;
  }

  .news-post-page .section1 {
    margin: 10% 5%;
  }

  .news-post-page .content {
    margin: 10% 5%;
  }

  .news-post-page .content p {
    font-size: 15px;
    line-height: 25px;
    margin: 10px 0;
    word-break: keep-all;
  }

  .news-post-page .related-news-wrap {
    margin: 10% 5%;
  }

  .news-post-page .related-news h3 {
    font-size: 18px;
    line-height: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .news-post-page .related-news .list img {
    width: 18px;
    padding: 0 12px 0 20px;
  }

  .news-post-page .related-news .list span {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }

  .news-post-page .related-news-wrap .swiper-slide {
    border: 3px solid #ddd;
    height: 300px;
  }

  .news-post-page .related-news-wrap .swiper-slide .content .text-wrap {
    padding: 20px;
    height: 100px;
  }

  .news-post-page .related-news-wrap .swiper-slide .content .text-wrap .title {
    height: 100px;
    margin: 0;
    font-size: 1.75em;
  }

  /* 뉴스레터 */

  .newsletter-page {
    margin: 5%;
    padding-top: 72px;
  }

  .newsletter-page .card-wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .newsletter-page .newsletter-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    max-width: 95%;
    padding: 20px 10px;
  }

  .newsletter-page .newsletter-content .mark {
    height: 1em;
  }

  .newsletter-page .newsletter-content .title {
    font-weight: bold;
    text-align: left;
    font-size: 2em;
    line-height: 1.5em;
    height: 100px;
  }

  /* 뉴스레터 게시물 */

  .newsletter-post-page .process {
    margin: 10% 5%;
  }

  .newsletter-post-page .title {
    margin: 10% 5%;
  }

  .newsletter-post-page .section1 {
    margin: 10% 5%;
  }

  .newsletter-post-page .content {
    margin: 10% 5%;
  }

  .newsletter-post-page .related-news {
    margin: 10% 5%;
  }

  .newsletter-post-page .related-news .list {
    right: 5%;
  }

  .newsletter-post-page .related-news-wrap .swiper-container {
    margin: 10% 5%;
  }

  .newsletter-post-page .related-news-wrap .swiper-wrapper .newsletter-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
    min-height: 200px;
  }

  .newsletter-post-page
    .related-news-wrap
    .swiper-wrapper
    .newsletter-content
    .title {
    font-size: 2em;
    line-height: 1em;
    height: 130px;
  }

  .newsletter-post-page
    .related-news-wrap
    .swiper-wrapper
    .newsletter-content
    .bottom {
    height: 30px;
  }

  /* 기술 도움 센터 */

  .support-center-page {
    padding-top: 0;
  }

  .support-center-page .theme-select button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #888;
    position: relative;
    padding: 5px;
    margin: 10px;
  }

  .support-center-page > .section01 .text-wrap .p1 {
    font-weight: bold;
    font-size: 16px;
  }

  .support-center-page > .section01 .text-wrap h1 {
    font-size: 18px;
    margin: 10px 0;
    margin-bottom: 20px;
    line-height: 25px;
  }

  .support-center-page .main {
    height: 300px;
  }

  .support-center-page .main .text-wrap {
    top: 40%;
    left: 10%;
    transform: none;
  }

  .support-center-page .main .text-wrap p {
    font-size: 2em;
    font-weight: bold;
  }

  .support-center-page .main .text-wrap h1 {
    font-size: 4em;
    font-weight: bold;
    margin: 0;
  }

  .support-center-page .theme-select {
    top: 0px;
  }

  .support-center-page .section02 .content .text-wrap .p1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .support-center-page > .section01 {
    min-height: 750px;
    margin: 5% 5% 0px 5%;
  }
  
  .support-center-page > .section01 .text-wrap {
    height: 100%;
    width: 100%;
  }

  .support-center-page > .section01 .img-wrap {
    position: relative;
    top:0;
  }

  .support-center-page > .section01 .text-wrap .p2 {
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    word-break: keep-all;
  }

  .support-center-page > .sub-title {
    font-size: 35px;
  }

  .support-center-page .section02 .content {
    display: block;
    margin: 10% 0%;
  }

  .support-center-page .section02 .content .text-wrap {
    padding: 0;
  }

  .support-center-page .section02 .content .text-wrap .p2 {
    font-size: 15px;
    margin: 0;
    margin-top: 10px;
    line-height: 25px;
    font-weight: 500;
    word-break: keep-all;
  }

  .support-center-page
    .section02
    .content
    .text-wrap
    .support-center-page
    .section02
    .content
    .text-wrap
    ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .support-center-page .section02 .content .img-wrap img {
    height: auto;
  }

  .support-center-page .section03 {
    margin: 5%;
  }

  .support-center-page .section04 {
    margin: 5%;
  }

  .support-center-page .section04 .members-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 150px 10px;
  }

  .support-center-page .section04 .members-wrap .lawyer-item img {
    max-width: 80%;
    width: 80%;
  }
  .support-center-page .section04 .members-wrap .lawyer-item .text-wrap {
    min-width: 170px;
    max-width: 176px;
  }

  .support-center-page .section04 .members-wrap .lawyer-item .text-wrap .p1 {
    margin-top: 10px;
    font-size: 22px;
  }

  .support-center-page .section04 .members-wrap .lawyer-item .text-wrap .p2 {
    font-size: 12px;
  }

  .support-center-page .section04 .members-wrap .lawyer-item .text-wrap .p3 {
    font-size: 12px;
  }

  .support-center-page .section05 {
    margin: 0 5% 5% 5%;
  }

  .support-center-page .section05 .sub-title p {
    font-size: 24px;
    line-height: 24px;
  }

  .support-center-page .section05 .newsletter-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    max-width: 338px;
    min-height: 150px;
    max-height: 262px;
  }

  .support-center-page .section05 .newsletter-content .mark {
    height: 20px;
  }

  .support-center-page .section05 .newsletter-content .mark span {
    font-size: 12px;
    line-height: 12px;
  }

  .support-center-page .section05 .newsletter-content .title {
    height: 56px;
    margin: 10px 0;
    line-height: 18.2px;
    font-size: 14px;
    word-break: normal;
  }

  .support-center-page .section05 .newsletter-content .bottom {
    font-size: 14px;
    line-height: 14px;
  }
  .support-center-page .section05 .newsletter-content:hover {
    border: 3px solid #1676bc;
  }

  .support-center-page .section05 .nav-button {
    display: none;
  }

  /* 테크 센터  */

  .tech-center-page {
    padding-top: 0;
  }

  .tech-center-page .main {
    height: 300px;
  }

  .tech-center-page .main .text-wrap {
    top: 40%;
    left: 10%;
    transform: none;
  }

  .tech-center-page .main .text-wrap p {
    font-size: 2em;
    font-weight: bold;
  }

  .tech-center-page .main .text-wrap h1 {
    font-size: 4em;
    font-weight: bold;
    margin: 0;
  }

  .tech-center-page .theme-select {
    top: 0px;
  }

  .tech-center-page > .section01  {
    margin: 5%;
  }

  .tech-center-page > .section01 .text-wrap h1 {
    font-size: 18px;
  }

  .tech-center-page > .section01 .text-wrap .p2 {
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    word-break: keep-all;
    text-align: center;
  }

  .tech-center-page > .sub-title {
    font-size: 35px;
  }

  .tech-center-page .section02 {
    margin: 5%;
  }

  .tech-center-page .section02 .content {
    display: block;
    margin: 10% 0%;
  }

  .tech-center-page .section02 .content .text-wrap {
    padding: 0;
    padding-top: 10px;
  }

  .tech-center-page .section02 .content .text-wrap .p1 {
    line-height: 25px;
    text-align: left;
  }

  .tech-center-page .section02 .content .text-wrap .p2 {
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    word-break: keep-all;
  }
  .tech-center-page .section02 .content .img-wrap img {
    width: 100%;
  }

  .tech-center-page .section03 {
    margin: 5%;
  }

  .tech-center-page .section04 .toggle-button {
    margin: 100px auto;
  }

  .tech-center-page .section04 {
    margin: 5%;
    padding: 0;
  }

  .tech-center-page .section04 .members-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 150px 10px;
  }

  .tech-center-page .section04 .members-wrap .lawyer-item img {
    max-width: 80%;
    width: 80%;
  }

  .tech-center-page .section04 .members-wrap .lawyer-item .text-wrap {
    min-width: 170px;
    max-width: 175px;
    margin-left: 10px;
  }

  .tech-center-page .section04 .members-wrap .lawyer-item .text-wrap .p1 {
    margin-top: 10px;
    margin-right: 5px;
    font-size: 22px;
  }

  .tech-center-page .section04 .members-wrap .lawyer-item .text-wrap .p2 {
    margin: 0;
    font-size: 12px;
  }

  .tech-center-page .section04 .members-wrap .lawyer-item .text-wrap .p3 {
    font-size: 12px;
  }

  .tech-center-page .section05 {
    margin: 35% 5% 5% 5%;
  }

  .tech-center-page .section05 .sub-title p {
    font-size: 24px;
    line-height: 24px;
  }

  .tech-center-page .section05 .newsletter-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    max-width: 338px;
    min-height: 150px;
    max-height: 262px;
  }

  .tech-center-page .section05 .newsletter-content .mark {
    height: 20px;
  }

  .tech-center-page .section05 .newsletter-content .mark span {
    font-size: 12px;
    line-height: 12px;
  }

  .tech-center-page .section05 .newsletter-content .title {
    height: 56px;
    margin: 10px 0;
    line-height: 18.2px;
    font-size: 14px;
  }

  .tech-center-page .section05 .newsletter-content .bottom {
    font-size: 14px;
    line-height: 14px;
  }

  .tech-center-page .section05 .nav-button {
    display: none;
  }

  .support-center-page .section05 .newsletter-content:hover {
    border: 3px solid #1676bc;
  }

  .landing-search-page .newsletter-section .content .newsletter-content {
    padding: 20px 10px 10px 10px;
    min-height: 200px;
    max-height: 262px;
  }

  .landing-search-page .newsletter-section .content .newsletter-content .mark {
    height: 1.5em;
  }

  .landing-search-page
    .newsletter-section
    .content
    .newsletter-content
    .mark
    span {
    height: 1em;
  }

  .landing-search-page .newsletter-section .content .newsletter-content .title {
    height: 150px;
    font-size: 1.5em;
    line-height: 2em;
  }

  /* 검색결과 상세 페이지 */

  .landing-search-detail-page .process {
    margin: 10% 5%;
  }

  .landing-search-detail-page .sub-title {
    margin: 10% 5%;
  }

  .landing-search-detail-page .content-wrap {
    margin: 10% 5%;
  }
}

@media (max-width: 900px) {
  .news-page .news-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .news-post-page .content img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    margin-bottom: 50px;
  }
}

@media (max-width: 700px) {

  .members-page .lawyer-section .grid-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(200px, auto);
    justify-items: center;
    gap: 100px 0;
  }
  .members-page .title-section .search-category-wrap button {
    border: 1px solid #1676bc;
    padding: 4px 8px;
  }

  .members-page .title-section .search-category-wrap button span {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }

  .members-page .title-section .search-category-wrap {
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media (max-width: 1627px) {
  .news-page .news-grid {
    padding: 30px 5% 50px 5%;
  }
  .news-page .theme-select {
    margin: 100px 5% 0 0px;
  }
  .news-page .press-grid {
    display: grid;
    gap: 15px;
    padding: 30px 5% 50px 5%;
    max-width: 1600px;
    margin: 0 auto;
  }

  .news-page .press-item {
    width: 100%;
    height: 136px;
    border: 3px solid #dbdbdb;
    overflow: hidden;
    background-color: white;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 650px) {
  .news-page .theme-select {
    margin: 50px 0px 0px 0px;
  }

  .news-page .search-bar .search-icon {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 24px;
  }

  .news-page .news-grid {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }

  .news-page .press-grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px 0px 50px 5px;
    place-items: center;
  }
  .news-page .press-item label {
    font-size: 12px;
    line-height: 14px;
    padding: 4px 8px;
  }

  .news-page .press-item h2 {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 315px;
  }

  .news-page .press-item {
    width: 335px;
    height: 92px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
    background-color: white;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: normal;
    align-items: flex-start;
  }

  .news-page .press-item h2 {
    padding-top: 8px;
    padding-bottom: 12px;
  }

  .news-page .theme-select-wrap {
    gap: 20px;
  }

  .news-page .news-wrapper {
    justify-content: normal;
    height: auto;
    gap: 24px;
  }

  .news-page .news-item {
    width: 329px;
    height: 425px;
}

  .news-page .news-item img {
    height: 329px;
  }

  .news-page .news-content {
    padding: 10px;
}

.news-page .news-title {
  height: 35px;
  -webkit-line-clamp: 2;
}

  .landing-search-page .no-result {
    text-align: center;
    margin: 84px auto;
  }
}

/* 건설/부동산 센터 페이지 */
@media (max-width: 1760px) {
  .property-center-page .section02 .content {
    flex-direction: column-reverse;
    align-items: center;
    margin: 30px auto;
  }
  
  .property-center-page .section02 .content .img-wrap01 img {
    width: 100%;
    height: auto;
  }
  
  .property-center-page .section02 .content .img-wrap02 img {
    width: 100%;
    height: auto;
  }
  
  .property-center-page .section02 .content .img-wrap03 img {
    width: 100%;
    height: auto;
  }
  
  .property-center-page .section02 .content .img-wrap04 img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1440px) {
  .property-center-page .theme-select {
    top: 0;
  }
  
  .property-center-page .main {
    height: 300Px;
  }
  
  .property-center-page .section02 .content .text-direction {
    gap: 40px;
  }
}

@media (max-width: 1080px) {
  .property-center-page {
    padding-top: 0;
  }

  .property-center-page .main .text-wrap {
    top: 40%;
    left: 10%;
    transform: none;
  }
  
  .property-center-page .main .text-wrap p {
    font-size: 2em;
    font-weight: bold;
  }
  
  .property-center-page .main .text-wrap h1 {
    font-size: 4em;
    font-weight: bold;
    margin: 0;
  }

  .property-center-page > .sub-title {
    font-size: 35px;
  }
  
  .property-center-page > .section01 {
    position: relative;
    max-height: 1300px;
  }

  .property-center-page > .section01 img {
    position: relative;
    width: 100%;
    right: 0px;
    bottom: 122vh;
  }
  
  .property-center-page .theme-select {
    margin: 5%;
  }
  
  .property-center-page .strength-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    gap: 0px;
  }
  
  .property-center-page .strength-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 335px;
    height: 399px;
    margin-top: 20px;
    z-index: 1;
  }
  
  .property-center-page .strength-card h1 {
    font-size: 20px;
  }
  
  .property-center-page .strength-card h3 {
    font-size: 14px;
  }
  
  .property-center-page .section02 {
    padding: 0 5%;
  }
  
  .property-center-page .section02 .content {
    align-items: center !important;
  }
  
  .property-center-page .section02 .content h3 {
    margin: 10px 0px 10px 0px !important;
    font-size: 20px;
    width: 335px;
    text-align: left;
  }
  
  .property-center-page .section02 .content .img-wrap01 img {
    width: 335px;
    height: 169px;
  }
  
  .property-center-page .section02 .content .img-wrap02 img {
    width: 335px;
    height: 214px;
  }
  
  .property-center-page .section02 .content .img-wrap03 img {
    width: 335px;
    height: 138px;
  }
  
  .property-center-page .section02 .content .img-wrap04 img {
    width: 335px;
    height: 66px;
  }
  
  .property-center-page .section02 .content .text-wrap {
    padding-left: 0px;
  }
  
  .property-center-page .section02 .content .text-wrap .h3 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 130%;
    letter-spacing: -0.01em;
  }
  
  .property-center-page .section02 .content .text-wrap .p {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.01em;
  }
  
  .property-center-page .section02 .content .text-ul {
    width: 336px;
    font-size: 14px;
    text-align: left;
    left: 20px;
    min-width: 336px;
  }
  
  .property-center-page .section02 .content .text-ul li::before {
    content: "•";
    position: absolute;
    left: -15px;
  }
  
  .property-center-page .section02 .content .text-direction {
    flex-direction: column;
    gap: 10px;
  }
  
  .property-center-page .section04 .toggle-button {
    margin: 100px auto;
  }
  
  .property-center-page .section04 {
    margin: 5%;
    padding: 0;
  }

  .property-center-page .section04 .members-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 150px 10px;
  }
  
  .property-center-page .section04 .members-wrap .lawyer-item img {
    max-width: 80%;
    width: 80%;
  }
  
  .property-center-page .section04 .members-wrap .lawyer-item .text-wrap {
    min-width: 170px;
    max-width: 175px;
    margin-left: 10px;
  }
  
  .property-center-page .section04 .members-wrap .lawyer-item .text-wrap .p1 {
    margin-top: 10px;
    margin-right: 5px;
    font-size: 22px;
  }
  
  .property-center-page .section04 .members-wrap .lawyer-item .text-wrap .p2 {
    margin: 0;
    font-size: 12px;
  }
  
  .property-center-page .section04 .members-wrap .lawyer-item .text-wrap .p3 {
    font-size: 12px;
  }

  .property-center-page .section05 {
    margin: 35% 5% 5% 5%;
  }
  
  .property-center-page .section05 .sub-title p {
    font-size: 24px;
    line-height: 24px;
  }
  
  .property-center-page .section05 .swiper-slide {
    position: relative;
    min-width: 160px;
  }
  
  .property-center-page .section05 .newsletter-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    height: auto;
  }
  
  .property-center-page .section05 .newsletter-content .mark {
    height: 20px;
  }
  
  .property-center-page .section05 .newsletter-content .mark span {
    font-size: 12px;
    line-height: 12px;
  }
  
  .property-center-page .section05 .newsletter-content .title {
    height: auto;
    min-height: 56px;
    margin: 10px 0;
    line-height: 18.2px;
    font-size: 14px;
  }
  
  .property-center-page .section05 .newsletter-content .bottom {
    font-size: 14px;
    line-height: 14px;
  }
  
  .property-center-page .section05 .nav-button {
    display: none;
  }
}

@media (max-width: 400px) {
  .landing-page .newsletter-section .newsletter-content .mark span {
    font-size: 9px;
    text-wrap: nowrap;
  }

  .support-center-page .main {
    height: 203px;
  }

  .support-center-page .main .text-wrap {
    top: 50%;
  }

  .support-center-page .theme-select button {
    font-size: 14px;
    text-wrap: nowrap;
    margin: 5px;
  }
  
  .support-center-page > .section01 {
    min-height: 830px;
  }

  .support-center-page > .section01 .text-wrap .p2 {
    text-align: left;
  }

  .tech-center-page .main {
    height: 203px;
  }

  .tech-center-page .main .text-wrap {
    top: 50%;
  }

  .tech-center-page .theme-select button {
    font-size: 14px;
    text-wrap: nowrap;
    margin: 5px;
  }

  .property-center-page .main {
    height: 203px;
  }

  .property-center-page .main .text-wrap {
    top: 50%;
  }

  .property-center-page .theme-select button {
    font-size: 14px;
    text-wrap: nowrap;
    margin: 5px;
  }

  .services-detail-page .recent-news .newsletter-content .mark span {
    font-size: 9px;
    text-wrap: nowrap;
  }
  .member-profile-page .recent-news .newsletter-content .mark span {
    font-size: 9px;
    text-wrap: nowrap;
  }
  .support-center-page .section05 .newsletter-content .mark span {
    font-size: 9px;
    text-wrap: nowrap;
  }
  .tech-center-page .section05 .newsletter-content .mark span {
    font-size: 9px;
    text-wrap: nowrap;
  }

  .property-center-page .section05 .newsletter-content .mark span{
    font-size: 9px;
    text-wrap: nowrap;
  }
}
