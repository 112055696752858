.header {
  position: fixed;
  top: 0;
  height: 72px;
  width: 100%;
  left: 0;
  right: 0;
  /* max-width: 1920px; */
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header .header-section {
  display: flex;
  align-items: center;
}

/* .header .center-header {
  height: 72px;
  display: flex;
  align-items: center;
  margin-left: 10%;
} */

/* .header .center-header > a {
  padding: 20px;
} */

.header .logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 260px;
}

.header .logo-image {
  height: 48px;
  /* margin-right: 10px; */
}

.header-mob .logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
}

.header-mob .logo-image {
  height: 30px;
}

.header .logo-text {
  font-size: 1.5em;
  color: #0056b3;
}

.header .nav ul {
  list-style: none;
  display: flex;
  gap: 80px;
  margin: 0;
  padding: 0;
}

/* .header .nav li {
  width: 100px;
} */

.header .nav a {
  text-decoration: none;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.header .nav a:hover {
  font-weight: bold;
}

.header .icons {
  display: flex;
  gap: 24px;
  margin-right: 260px;
}

.header .icon {
  cursor: pointer;
  height: 24px;
}

.footer {
  background-color: #697077;
  padding: 40px 60px;
}

.footer-content .section1 {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
}

.footer-content .section1 .footer-icons img {
  height: 24px;
  padding-left: 20px;
  cursor: pointer;
}

.footer-content .footer-logo-image {
  height: 46px;
  margin-right: 10px;
}

.footer-content .section2 {
  color: white;
  font-size: 1em;
  line-height: 1em;
  margin: 20px 0;
}

.footer-content .section2 p {
  margin: 15px 0;
}

.footer-content .section2 .footer-links .bar {
  margin: 0 20px;
}

/* 사이드 버튼 */
.side-button-container {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 270px;
  transform: translateY(-100%);
  display: flex;
  font-weight: 500;
  font-size: 1.25em;
  transition: left 0.3s ease;
}

.swiper {
  margin-left: 0px !important;
}
.swiper-slide {
  max-width: 305px;
}

@media screen and (min-width: 1080px) {
  .side-button-container {
    left: calc(50% - 540px);
  }
}

.side-button-container .section1 {
  display: flex;
  flex-direction: column;
}

.side-button-container .side-button {
  background-color: #fff;
  color: #1676bc;
  border: none;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 600;
}

.side-button-container .side-button a {
  text-decoration: none;
  color: #1676bc;
}

.side-button-container .section2 {
  border-radius: 0 15px 15px 0;
  color: #fff;
  background-color: #1676bc;
  writing-mode: vertical-rl;
  text-align: center;
  padding: 20px 18px;
}

.side-button:hover {
  background-color: #ccc;
}

/* 탑버튼 */

.top-btn {
  width: 48px;
  height: 48px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: none;
  cursor: pointer;
  z-index: 1000;
  background-color: transparent;
}

.top-btn img {
  width: 100%;
}

/* 팝업 */

.popup-wrap {
  position: relative;
  top: 430px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.popup-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 25%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 460px;
}

.popup-btn-wrap {
  display: flex;
}

.popup-btn {
  width: 50%;
  height: 60px;
  background-color: #1676bc;
  border: none;
  border-color: #1676bc;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

/* 랜딩페이지 */

.landing-page {
  padding-top: 72px;
}

.landing-page .main-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 1080px;
  position: relative;
}

.landing-page .main-section .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.landing-page .main-section .main-content {
  text-align: center;
  width: 100%;
  z-index: 1;
  position: relative;
  bottom: 60px;
}

.landing-page .main-section .main-title {
  font-size: 28px;
  color: #0e50d6;
  font-weight: 900;
  line-height: 28px;
}

.landing-page .main-section .main-subtitle {
  font-size: 40px;
  color: #ffffff;
  margin-top: 20px;
  line-height: 52px;
  font-weight: 700;
}

.landing-page .main-section .search-bar {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 16px 24px;
  width: 100%;
  max-width: 653px;
  margin: 46px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 2px solid #999999;
  box-sizing: border-box;
}

.landing-page .main-section .category-select {
  width: 160px;
  border: none;
  background: transparent;
  font-size: 16px;
  color: #333;
  padding: 10px;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
}

.landing-page .main-section .search-icon {
  height: 24px;
  padding-left: 10px;
  cursor: pointer;
}

.landing-page .main-section .search-input {
  flex-grow: 1;
  border: none;
  font-size: 16px;
  outline: none;
  background: transparent;
}

.landing-page .main-section .search-button {
  border: none;
  background: transparent;
  font-size: 16px;
  color: #111111;
  cursor: pointer;
  padding: 10px;
  outline: none;
}

.landing-page .main-section .search-button:hover {
  color: #333;
}

.landing-page .main-section .keyword-recommendation {
  position: absolute;
  top: 100%;
  left: -2px;
  right: -2px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 16px 16px;
  align-items: center;
  gap: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border: 2px solid #999;
  box-sizing: border-box;
  border-top: none;
}

.landing-page .main-section .keyword-item {
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
}

.landing-page .main-section .keyword-item:hover {
  background-color: #007bff;
  color: #fff;
}

/* 랜딩페이지 - 뉴스섹션 */

.landing-page .news-section {
  margin: 100px 0px 100px 0px;
  text-align: center;
}

.landing-page .news-section > .title {
  text-align: left;
  color: #333333;
  max-width: 1320px;
  margin: 0 auto;
}

.landing-page .news-section .title p {
  font-size: 5em;
  font-family: "Times New Roman", Times, serif;
  margin: 0px;
}

.landing-page .news-section .title p span {
  color: #1676bc;
  font-size: 1.625em;
}

.landing-page .news-section img {
  width: 100%;
}

.landing-page .news-section .title .more {
  border: none;
  background: none;
  padding-left: 20px;
  cursor: pointer;
}

.landing-page .news-section .title .more img {
  width: 50px;
}

.landing-page .news-section > .content {
  display: flex;
  justify-content: center;
  height: 500px;
  align-items: flex-end;
  gap: 40px;
  max-width: 1320px;
  margin: 0 auto;
}

.landing-page .news-section .content .img-section-wrap {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 500px;
  padding-left: 200px;
  background: url("../src/assets/images/Ellipse.png") no-repeat center left;
}

.landing-page .news-section .content .img-section {
  min-width: 450px;
  min-height: 450px;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
}

.landing-page .news-section .content .img-section img {
  min-width: 450px;
  min-height: 450px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.landing-page .news-section .content .text-section {
  text-align: left;
  width: 630px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  flex: 3;
}

.landing-page .news-section .content .text-section .text-wrap {
  opacity: 1;
  transition: opacity 0.5s;
  width: 630px;
  font-size: 18px;
}

.landing-page .news-section .content .text-section .text-wrap.fade-out {
  opacity: 0;
}

.landing-page .news-section .content .text-section .text-wrap.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.landing-page .news-section .content .text-section .title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 26px;
  color: #111;
}

.landing-page .news-section .content .text-section .content {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 23.4px;
  font-weight: 500;
  color: #666;
}

.landing-page .news-section .content .text-section .nav {
  width: 166px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

.landing-page .news-section .content .text-section .nav button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.landing-page .news-section .content .text-section .nav img {
  width: 24px;
  height: 24px;
}

.landing-page .news-section .content .text-section .nav span {
  font-size: 20px;
  color: #1676bc;
  font-weight: 500;
  line-height: 20px;
}

.landing-page .theme-select {
  margin: 60px 260px;
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
  gap: 40px;
}

.landing-page .theme-select button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 0;
  min-width: 140px;
  font-weight: 500;
}

.landing-page .theme-select span.active {
  font-weight: bold;
  color: black;
  border-bottom: 4px solid black;
}

.landing-page .theme-select button:hover {
  color: black;
  font-weight: bold;
}

.landing-page .news-section .content .text-section .more {
  border: none;
  background: none;
  color: #999999;
  padding: 0;
  border-bottom: 1px solid #999999;
  cursor: pointer;
  font-size: 16px;
}

/* 랜딩페이지 > 뉴스레터섹션 */

.landing-page .newsletter-section {
  text-align: center;
  margin: 100px auto;
}

.landing-page .newsletter-section > .title {
  text-align: left;
  margin: 0 auto;
  max-width: 1320px;
}

.landing-page .newsletter-section .title p {
  font-size: 5em;
  font-family: "Times New Roman", Times, serif;
  margin: 0px;
  line-height: 0.8;
}

.landing-page .newsletter-section .title p span {
  color: #1676bc;
  font-size: 1.625em;
}

.landing-page .newsletter-section .title .more {
  border: none;
  background: none;
  padding-left: 20px;
  cursor: pointer;
}

.landing-page .newsletter-section .title .more img {
  width: 50px;
}

/* 랜딩페이지 > 뉴스레터 슬라이드 */

.landing-page .newsletter-section .swiper-container {
  padding: 0;
  margin: 68px auto;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1232px;
}

.landing-page .newsletter-section .swiper-wrapper {
  padding-bottom: 24px;
}

.landing-page .newsletter-section .swiper-scrollbar {
  position: static;
  width: 100%;
}

.landing-page .newsletter-section .newsletter-content {
  box-sizing: border-box;
  border: 3px solid #d9d9d9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 301px;
  min-width: 160px;
  min-height: 125px;
  max-height: 230px;
}

.landing-page .newsletter-section .newsletter-content:hover {
  border: 3px solid #1676bc;
}

.landing-page .newsletter-section .newsletter-content .mark {
  text-align: left;
  height: 34px;
  align-items: center;
  display: flex;
}

.landing-page .newsletter-section .newsletter-content .mark span {
  background-color: #1676bc;
  color: white;
  padding: 8px;
  font-size: 1em;
  align-items: center;
  height: 1em;
  font-weight: bold;
}

.landing-page .newsletter-section .newsletter-content .title {
  height: 200px;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  margin: 20px 0;
  line-height: 20.8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.landing-page .newsletter-section .newsletter-content .bottom {
  height: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #999;
  align-items: center;
  margin-top: auto;
}

.landing-page .newsletter-section .newsletter-content .bottom .date {
  font-size: 16px;
  color: #999;
}

.landing-page .newsletter-section .newsletter-content .bottom a {
  text-decoration: none;
  color: #999;
}

.landing-page .newsletter-section .newsletter-content .bottom .more {
  text-decoration: underline;
  cursor: pointer;
}

.landing-page .newsletter-section .swiper-scrollbar-drag {
  background: #1676bc;
}

.landing-page .newsletter-section .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.landing-page .newsletter-section .nav-button.prev {
  left: -80px;
}

.landing-page .newsletter-section .nav-button.next {
  right: -80px;
}

.landing-page .newsletter-section .nav-button img {
  width: 48px;
}

/* 랜딩페이지 > PROFESSIONAL */

.landing-page .hired-section {
  text-align: center;
  margin: 100px auto;
  max-width: 1320px;
}

.landing-page .hired-section .title {
  text-align: left;
  margin-bottom: 60px;
}

.landing-page .hired-section .title p {
  font-size: 5em;
  font-family: "Times New Roman", Times, serif;
  margin: 0px;
  line-height: 0.8;
}

.landing-page .hired-section .title p span {
  color: #1676bc;
  font-size: 1.625em;
}

.landing-page .hired-section .title .more {
  border: none;
  background: none;
  padding-left: 30px;
  cursor: pointer;
}

.landing-page .hired-section .title .more img {
  width: 50px;
}

.landing-page .hired-section .content-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1320px;
  margin: 0 auto;
}

.landing-page .hired-section .content-wrap .section01 {
  flex-basis: 30%;
  flex-shrink: 0;
  position: relative;
}

.landing-page .hired-section .content-wrap .section01 .slider-content {
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.landing-page .hired-section .content-wrap .section01 .slider-content.fade {
  opacity: 0;
}

.landing-page .hired-section .content-wrap .section01 .img-wrap img {
  max-height: 500px;
  width: 311px;
}

.landing-page .hired-section .content-wrap .section01 .text-wrap {
  position: relative;
  text-align: left;
  padding: 15px 0px 0px 80px;
}

.landing-page .hired-section .content-wrap .section01 .text-wrap p {
  font-weight: 400;
}

.landing-page .hired-section .content-wrap .section01 .text-wrap .name {
  font-size: 40px;
  margin-right: 10px;
}

.landing-page .hired-section .content-wrap .section01 .text-wrap .job {
  font-size: 24px;
  color: #999999;
}

.landing-page .hired-section .content-wrap .section01 .text-wrap .email {
  margin: 10px 0px;
  font-size: 18px;
  color: #999999;
}

.landing-page .hired-section .content-wrap .section01 .text-wrap .class-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.landing-page .hired-section .content-wrap .section01 .text-wrap .class {
  border: 1px solid #999999;
  color: #666666;
  font-size: 18px;
  line-height: 18px;
  width: fit-content;
  padding: 2px 4px;
}

.landing-page .hired-section .content-wrap .section01 .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.landing-page .hired-section .content-wrap .section01 .nav-button.prev {
  left: -40px;
}

.landing-page .hired-section .content-wrap .section01 .nav-button.next {
  right: -40px;
}

.landing-page .hired-section .content-wrap .section01 .nav-button.prev img,
.landing-page .hired-section .content-wrap .section01 .nav-button.next img {
  width: 36px;
}

.landing-page .hired-section .content-wrap .section01 .nav-button:hover {
  color: #555;
}

/* 랜딩페이지 > PROFESSIONAL > 슬라이드 */

.landing-page .hired-section .swiper-container {
  flex-basis: 70%;
  flex-shrink: 0;
  width: 100%;
  max-width: 798px;
  height: 500px;
}

.landing-page .hired-section .swiper {
  width: 100%;
  height: 100%;
}

.landing-page .hired-section .swiper-slide {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.landing-page .hired-section .content .img-wrap {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.landing-page .hired-section .content .img-wrap img {
  width: 186px;
}

.landing-page .hired-section .swiper-scrollbar-drag {
  background: #1676bc;
}

.landing-page .hired-section .content {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  margin-bottom: 30px;
  position: relative;
  /* transition: all 0.5s ease; */
  width: 290px;
  height: 400px;
}

.landing-page .hired-section .content .class-empty {
  width: 100%;
  height: 20px;
}

.landing-page .hired-section .content img {
  filter: grayscale(100%);
  cursor: pointer;
}

.landing-page .hired-section .content.active,
.landing-page .hired-section .content:hover {
  filter: grayscale(0%);
}

.landing-page .hired-section .content.active img,
.landing-page .hired-section .content:hover img {
  filter: grayscale(0%);
}

.landing-page .hired-section .content img {
  filter: grayscale(100%);
  max-height: 344px;
}

.landing-page .hired-section .content .text-wrap {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 5px;
  margin-left: 35px;
}

.landing-page .hired-section .content .text-wrap p {
  width: 100%;
  font-weight: 400;
  margin: 0px;
}

.landing-page .hired-section .content .text-wrap .job {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 16px;
}

.landing-page .hired-section .content .text-wrap .name {
  font-size: 28px;
  color: #333;
  font-weight: 400;
  margin-right: 10px;
  line-height: 28px;
}

.landing-page .hired-section .content .text-wrap .email {
  width: 100%;
  color: #999999;
}

.landing-page .hired-section .content .text-wrap .class-wrap {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.landing-page .hired-section .content .text-wrap .class {
  font-size: 14px;
  line-height: 14px;
  color: #666;
  border: 1px solid #d9d9d9;
  text-align: start;
  width: fit-content;
  padding: 2px 4px;
}

/* 랜딩페이지 > 로백스 맵 */

.landing-page .lawvax-map-section {
  text-align: center;
  margin: 100px auto;
  max-width: 1320px;
}

.landing-page .lawvax-map-section .title {
  text-align: left;
}

.landing-page .lawvax-map-section .title p {
  font-size: 5em;
  font-family: "Times New Roman", Times, serif;
  margin: 0px;
  line-height: 0.8;
}

.landing-page .lawvax-map-section .title p span {
  color: #1676bc;
  font-size: 1.625em;
}

.landing-page .lawvax-map-section .title p:first-child {
  font-size: 2em;
}

.landing-page .lawvax-map-section .content {
  display: flex;
  position: relative;
  margin-top: 60px;
  justify-content: space-between;
}

.landing-page .lawvax-map-section .content .text-section {
  text-align: left;
}

.landing-page .lawvax-map-section .content .search-bar {
  border: 2px solid #999;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 466px;
  box-sizing: border-box;
  padding: 16px 20px;
}

.landing-page .lawvax-map-section .content .search-bar img {
  width: 24px;
}

.landing-page .lawvax-map-section .content .search-bar input {
  border: none;
  padding: 0 28px;
  width: 100%;
  outline: none;
  font-size: 1em;
}

.landing-page .lawvax-map-section .content .search-bar button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  height: 24px;
}

.landing-page .lawvax-map-section .content .sub-wrap {
  padding: 14px 0;
}

.landing-page .lawvax-map-section .content .sub-wrap .adress {
  font-size: 20px;
  line-height: 20px;
  color: #333;
}

.landing-page .lawvax-map-section .content .sub-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.landing-page .lawvax-map-section .content .gray {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 18px;
}

.landing-page .lawvax-map-section .content p {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.landing-page .lawvax-map-section .content p img {
  width: 24px;
  cursor: pointer;
}
.landing-page .lawvax-map-section .content p img:last-child {
  margin-left: 2px;
}

.landing-page .lawvax-map-section .content .map-section {
  width: 800px;
}

.landing-page .lawvax-map-section .content .map-section .kakaomap-wrap {
  width: 100%;
  height: 452px;
}

.landing-page .lawvax-map-section .content .share {
  position: absolute;
  right: 0;
  top: 0px;
  right: 0;
  display: flex;
  align-items: center;
  color: #0056b3;
  border: 2px solid #1676bc;
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
}

.landing-page .lawvax-map-section .content .share img {
  width: 14px;
  padding-right: 10px;
}

.landing-page .lawvax-map-section .call span {
  display: flex;
  align-items: center;
  font-size: 1.25em;
  color: var(--mono-black-icon-2);
}

.landing-page .lawvax-map-section .call img {
  height: 24px;
  padding-right: 10px;
}

.landing-page .lawvax-map-section .traffic p {
  display: flex;
  align-items: center;
  font-size: 1.25em;
  margin-bottom: 12px;
  color: var(--mono-black-icon-2);
}

.landing-page .lawvax-map-section .traffic img {
  height: 24px;
  padding-right: 10px;
}

.landing-page .lawvax-map-section .map-section {
  align-items: end;
  display: flex;
}

.landing-page .lawvax-map-section .map-section img {
  margin: 50px;
  width: 100%;
  height: 100%;
}

/* 랜딩 메인 검색 페이지  */

.landing-search-page {
  padding-top: 72px;
}

.landing-search-page .process {
  display: flex;
  align-items: center;
  margin: 124px 260px 84px 260px;
}

.landing-search-page .process img {
  width: 24px;
}

.landing-search-page .process span {
  font-size: 1.5em;
  padding-left: 16px;
  color: #999999;
}

.landing-search-page .search-bar-wrap {
  padding-bottom: 60px;
}

.landing-search-page .search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  background-color: white;
  padding: 16px 24px;
  max-width: 472px;
  height: 22px;
  margin: 0 auto;
  position: relative;
}

.landing-search-page .search-bar input {
  flex-grow: 1;
  border: none;
  font-size: 1em;
  outline: none;
  background: transparent;
}

.landing-search-page .search-bar img {
  height: 24px;
  cursor: pointer;
}

.landing-search-page .no-result {
  text-align: center;
  margin: 84px 260px;
}

.landing-search-page .sub-title p {
  font-size: 40px;
  margin-bottom: 20px;
}

.landing-search-page .members-section {
  padding: 0 260px 84px 260px;
}

.landing-search-page .members-section .sub-title {
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-page .members-section .members-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px 0 48px 0;
}

.landing-search-page .members-section .members-wrap a {
  text-decoration: none;
  color: black;
}

.landing-search-page .members-section .members-wrap .lawyer-item {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.tech-center-page .section04 .members-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 150px 100px;
}

.landing-search-page .members-section .members-wrap .lawyer-item img {
  width: 100%;
  max-width: 186px;
}

.landing-search-page .members-section .members-wrap .lawyer-item .text-wrap {
  text-align: left;
  max-width: 200px;
}

.landing-search-page .members-section .members-wrap .lawyer-item .p1 {
  margin-top: 10px;
  font-size: 28px;
  color: #333333;
  display: inline-block;
}

.landing-search-page .members-section .members-wrap .lawyer-item .p2 {
  color: #999999;
  font-size: 16px;
  display: inline-block;
  margin-left: 10px;
}

.landing-search-page .members-section .members-wrap .lawyer-item .email {
  color: #999999;
  font-size: 16px;
  display: inline-block;
  margin: 5px 0;
}

.landing-search-page .members-section .members-wrap .lawyer-item .class-wrap {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.landing-search-page .members-section .members-wrap .lawyer-item .p3 {
  border: 1px solid #999999;
  color: #666666;
  font-size: 14px;
  width: fit-content;
  padding: 2px 4px;
  margin: 0;
}

.landing-search-page .members-section .toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #999999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 16px;
  position: relative;
  width: 100%;
  font-weight: bold;
}

.landing-search-page .members-section .toggle-button img {
  width: 20px;
  margin-left: 10px;
}

.landing-search-page .members-section .toggle-button::before,
.landing-search-page .members-section .toggle-button::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 1px;
  background-color: #e0e0e0;
}

.landing-search-page .members-section .toggle-button::before {
  left: 0;
}

.landing-search-page .members-section .toggle-button::after {
  right: 0;
}

.landing-search-page .service-section {
  padding: 125px 260px;
  background-color: #f9f9f9;
}

.landing-search-page .service-section .sub-title {
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-page .service-section ul li {
  list-style: disc;
  margin-left: 20px;
  padding: 1em 0;
  font-size: 1.5em;
  color: #666666;
}

.landing-search-page .news-section01 {
  padding: 125px 260px;
}

.landing-search-page .news-section01 .sub-title {
  text-align: left;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-page .sub-title .more {
  border: none;
  background: none;
  padding-left: 30px;
  cursor: pointer;
}

.landing-search-page .content .news-item {
  padding: 30px 0 28px 0;
}

.landing-search-page .content .news-item .content {
  padding: 10px 0 30px 0;
  line-height: 1.2;
}

.landing-search-page .sub-title .more img {
  width: 50px;
}

.landing-search-page .news-section01 .content .news-item {
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-page .news-section01 .content .news-item .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.landing-search-page .news-section01 .content .news-item .content {
  font-size: 18px;
  color: #666666;
}

.landing-search-page .news-section01 .content .news-item .date {
  font-size: 16px;
  color: #666666;
}

.landing-search-page .news-section02 {
  background-color: #f9f9f9;
  padding: 125px 260px;
}

.landing-search-page .news-section02 .sub-title {
  text-align: left;
}

.landing-search-page .news-section02 .content .news-item {
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-page .news-section02 .content .news-item .title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}

.landing-search-page .news-section02 .content .news-item .wrap {
  display: flex;
  font-size: 16px;
  color: #666666;
}

.landing-search-page .news-section02 .content .news-item .wrap .bar {
  margin: 0 10px;
}

.landing-search-page .news-section03 {
  padding: 125px 260px;
}

.landing-search-page .news-section03 .sub-title {
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-page .news-section03 .content .news-item {
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-page .news-section03 .content .news-item .title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}

.landing-search-page .news-section03 .content .news-item .content {
  font-size: 18px;
  color: #666666;
}

.landing-search-page .news-section03 .content .news-item .date {
  font-size: 16px;
  color: #666666;
}

.landing-search-page .newsletter-section {
  padding: 125px 260px;
}

.landing-search-page .newsletter-section .sub-title {
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-page .newsletter-section .content .swiper-container {
}

.landing-search-page
  .newsletter-section
  .content
  .swiper-container
  .swiper-wrapper {
  padding: 24px 0;
}

.landing-search-page .newsletter-section .content .newsletter-content {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  padding: 44px 28px;
  display: flex;
  flex-direction: column;
  max-width: 338px;
  min-height: 300px;
  max-height: 262px;
}

.landing-search-page .newsletter-section .content .newsletter-content .mark {
  text-align: left;
  height: 40px;
  align-items: center;
  display: flex;
}

.landing-search-page
  .newsletter-section
  .content
  .newsletter-content
  .mark
  span {
  background-color: #1676bc;
  color: white;
  padding: 8px;
  font-size: 1.25em;
  align-items: center;
  height: 24px;
  font-weight: bold;
}

.landing-search-page .newsletter-section .content .newsletter-content .title {
  height: 200px;
  font-weight: bold;
  text-align: left;
  font-size: 1.5em;
  margin: 28px 0;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.landing-search-page .newsletter-section .content .newsletter-content .bottom {
  height: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 1.25em;
  color: #888;
  align-items: center;
  margin-top: auto;
}

.landing-search-page
  .newsletter-section
  .content
  .newsletter-content
  .bottom
  .date {
  font-size: 1em;
  color: #888;
}

.landing-search-page
  .newsletter-section
  .content
  .newsletter-content
  .bottom
  a {
  text-decoration: none;
  color: #888;
}

.landing-search-page
  .newsletter-section
  .content
  .newsletter-content
  .bottom
  .more {
  text-decoration: underline;
  cursor: pointer;
}

.landing-search-page .newsletter-section .swiper-scrollbar-drag {
  background: #1676bc;
}

/* 랜딩 상세 검색 페이지 */

.landing-search-detail-page {
  padding-top: 72px;
}

.landing-search-detail-page .process {
  display: flex;
  align-items: center;
  margin: 125px 260px;
}

.landing-search-detail-page .process img {
  width: 24px;
}

.landing-search-detail-page .process span {
  font-size: 1.5em;
  padding-left: 15px;
  color: #999999;
}

.landing-search-detail-page .sub-title {
  margin: 125px 260px 0;
  border-bottom: 1px solid #d9d9d9;
}

.landing-search-detail-page .sub-title h1 {
  font-size: 3em;
  margin-bottom: 48px;
}

.landing-search-detail-page .content-wrap {
  margin: 0 260px 84px 260px;
}

.landing-search-detail-page .content-wrap .news-item {
  border-bottom: 1px solid #d9d9d9;
  padding: 24px 0;
}

.landing-search-detail-page .content-wrap .news-item .title {
  font-size: 1.5em;
  line-height: 36px;
  font-weight: 500;
}

.landing-search-detail-page .content-wrap .news-item .content {
  font-size: 1.25em;
  padding: 10px 0 24px 0;
  line-height: 24px;
  color: #666666;
  font-weight: 400;
}

.landing-search-detail-page .content-wrap .news-item .date {
  font-size: 1em;
  color: #666666;
}

.landing-search-detail-page .pagination {
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-search-detail-page .pagination img {
  width: 24px;
  cursor: pointer;
}

.landing-search-detail-page .page-number {
  margin: 0 5px;
  cursor: pointer;
  color: #555;
}

.landing-search-detail-page .page-number.active {
  font-weight: bold;
  color: #007bff;
}

.landing-search-detail-page .separator {
  margin: 0 5px;
  color: #bbb;
}

/* 로벡스 소개 페이지 */

.intro-page {
  padding-top: 72px;
}

.intro-page .process {
  display: flex;
  align-items: center;
  margin: 100px 300px;
}

.intro-page .process img {
  width: 18px;
}

.intro-page .process span {
  font-size: 1.125em;
  line-height: 1.125em;
  padding-left: 10px;
  font-weight: 500;
  color: #999999;
}

.intro-page .p2 {
  font-weight: bold;
  font-size: 2.5em;
  margin-bottom: 40px;
}

.intro-page .p3 {
  font-size: 17px;
  line-height: 36px;
  color: #111;
  word-break: keep-all;
}

.intro-page .section01 {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 40px 300px 0 300px;
  background-color: #fbfbfb;
}

.intro-page .section01 img {
  max-width: 400px;
  width: 400px;
}

.intro-page .section01 .img-wrap {
  padding: 0px 80px 30px 0px;
}

.intro-page .section01 .text-wrap {
  text-align: left;
  line-height: 1.2;
  margin: auto;
}

.intro-page .section01 h1 {
  font-size: 9.375em;
  font-weight: 400;
  font-family: "Times New Roman", Times, serif;
  margin: 124px 0 0 0;
  color: #f1f0f0;
}

.intro-page .section02 {
  background-image: url("../src/assets/images/intro01.png");
  background-size: cover;
  background-position: center;
  padding: 100px 839px 483px 260px;
  height: 272px;
}

.intro-page .section02 .p2 {
  font-weight: bold;
  font-size: 2.5em;
  line-height: 2.5em;
  margin-bottom: 40px;
  color: #111;
  line-height: 64px;
}

.intro-page .section02 .p3 {
  font-size: 17px;
  line-height: 36px;
  color: #111;
  max-width: 545px;
}

.intro-page .section0304-wrap {
  display: flex;
  width: 1320px;
  justify-content: space-between;
  margin: 100px auto;
}

.intro-page .section03 {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  height: 400px;
  width: 580px;
}

.intro-page .section03 .text-wrap {
  text-align: left;
  width: 580px;
  margin-bottom: 85px;
}

.intro-page .section03 .img-wrap {
  width: 580px;
  height: 368px;
}

.intro-page .section03 img {
  width: 580px;
  height: 360px;
}

.intro-page .section04 {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.intro-page .section04 .text-wrap {
  margin-top: 120px;
  width: 640px;
}

.intro-page .section04 .p2 {
  margin-bottom: 40px;
}

.intro-page .section04 .img-wrap {
  width: 640px;
  height: 332px;
}

.intro-page .section04 img {
  margin-top: 10px;
  width: 640px;
  height: 355px;
}

/* 업무 분야 페이지 */

.services-page {
  padding-top: 72px;
}

.services-page .process {
  display: flex;
  align-items: center;
  margin: 100px 300px;
}

.services-page .process img {
  width: 18px;
}

.services-page .process span {
  font-size: 18px;
  line-height: 15px;
  padding-left: 10px;
  color: #999999;
}

.services-page > .title {
  margin: 0px 300px;
}

.services-page .title p {
  font-size: 5em;
  font-family: "Times New Roman", Times, serif;
  line-height: 0.8;
}

.services-page .title p span {
  color: #1676bc;
  font-size: 1.625em;
}

.services-page .content-wrap {
  border-top: 1px solid #d9d9d9;
  margin: 60px 300px 100px 300px;
}

.services-page .content-wrap > div {
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer;
}

.services-page .content-wrap > div:hover {
  background-color: #f3f4f8;
}

.services-page .content-wrap > div:hover .fold-img {
  content: url(../src/assets/images/icons/up01.png);
  transform: rotate(90deg);
}

.services-page .content-wrap > div .title {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.services-page .content-wrap > div .open {
  background-color: #f3f4f8;
}

.services-page .content-wrap > div .title span {
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
}

.services-page .content-wrap > div .title img {
  width: 50px;
}

.services-page .content-wrap > div > .fold-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 15px 0;
  background-color: #fff;
}

.services-page .content-wrap > div > .fold-wrap a {
  text-decoration: none;
  color: black;
}

.services-page .content-wrap > div > .fold-wrap .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.services-page .content-wrap > div > .fold-wrap .content:hover {
  background-color: #f3f4f8;
  cursor: pointer;
}

.services-page .content-wrap > div > .fold-wrap .content p {
  margin: 0;
}

.services-page .content-wrap > div > .fold-wrap .content img {
  height: 20px;
  transition: all 0.3s ease;
}

.services-page .content-wrap > div > .fold-wrap .content:hover img {
  transform: rotate(-90deg);
}

/* 업무분야 상세 페이지 */

.services-detail-page {
  padding-top: 72px;
}

.services-detail-page .process {
  display: flex;
  align-items: center;
  margin: 100px 300px 100px 300px;
}

.services-detail-page .process img {
  width: 18px;
}

.services-detail-page .process span {
  font-size: 18px;
  line-height: 18px;
  padding-left: 10px;
  color: #999999;
}

.services-detail-page .title {
  margin: 100px 300px;
}

.services-detail-page .title p {
  font-family: "Times New Roman", Times, serif;
  font-size: 5em;
  line-height: 80px;
}

.services-detail-page .title p span {
  color: #1676bc;
  font-size: 1.625em;
}

.services-detail-page .sub-title {
  margin: 100px 300px 0 300px;
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
}

.services-detail-page .sub-title p {
  margin: 40px 0;
}

.services-detail-page .sub-title img {
  width: 100%;
}

.services-detail-page .content-wrap {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.services-detail-page .content-wrap .content-left {
  width: 75%;
}

.services-detail-page .content-wrap .content-left .sub-title-ment {
  padding: 40px 0 100px 300px;
}

.services-detail-page .content-wrap .content-left .sub-title-ment p {
  font-size: 18px;
  line-height: 30px;
  color: #333;
  font-weight: 500;
  white-space: break-spaces;
}

.services-detail-page .content-wrap .content-left .main-field-of-work {
  background-color: #f6f8fa;
  padding: 40px 80px 88px 300px;
  color: #333;
}

.services-detail-page .content-wrap .content-left .main-field-of-work h1 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #333;
}

.services-detail-page .content-wrap .content-left .main-field-of-work ul {
  padding: 60px 0;
  list-style: disc;
}

.services-detail-page .content-wrap .content-left .main-field-of-work li {
  display: flex;
  font-size: 18px;
  line-height: 23.4px;
  font-weight: 400;
}

.services-detail-page .content-wrap .content-left .main-field-of-work span {
  padding-left: 20px;
  font-size: 18px;
  line-height: 30px;
}

.services-detail-page .content-wrap .content-left .main-field-of-work .content {
  line-height: 30px;
  margin-bottom: 16px;
  flex-grow: 1;
}

.services-detail-page .content-wrap .content-left .main-field-of-work p {
  margin: 12px 0;
  font-size: 18px;
  line-height: 23.4px;
  font-weight: 400;
}

.services-detail-page .content-wrap .content-left .featured-cases {
  background-color: #f6f8fa;
  padding: 80px 0 80px 300px;
}

.services-detail-page .content-wrap .content-left .members {
  padding: 80px 0 0 300px;
  height: 100%;
}

.services-detail-page .content-wrap .content-left .members h1 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
  padding-bottom: 40px;
  margin-bottom: 40px;
  color: #333;
  border-bottom: 1px solid #d9d9d9;
}

.services-detail-page .content-wrap .content-left .members .members-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.services-detail-page
  .content-wrap
  .content-left
  .members
  .members-grid
  .members-item {
  max-width: 233px;
  min-height: 440px;
}

.services-detail-page .content-wrap .content-left .members .members-grid img {
  width: 100%;
  max-width: 180px;
}

.services-detail-page
  .content-wrap
  .content-left
  .members
  .p1
  span:first-child {
  font-size: 28px;
  line-height: 28px;
}

.services-detail-page .content-wrap .content-left .members .class-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.services-detail-page .content-wrap .content-left .members .p1 {
  margin-top: 20px;
}

.services-detail-page .content-wrap .content-left .members .p1 span:last-child {
  font-size: 16px;
  line-height: 16px;
  color: #999;
  margin-left: 10px;
}

.services-detail-page .content-wrap .content-left .members .p2 {
  color: #666666;
  font-size: 0.875em;
  margin: 5px 0;
  line-height: 24px;
}

.services-detail-page .content-wrap .content-left .members .p3 {
  border: 1px solid #999999;
  color: #666666;
  font-size: 14px;
  line-height: 14px;
  width: fit-content;
  padding: 2px 4px;
  margin-bottom: 4px;
}

.services-detail-page
  .content-wrap
  .content-left
  .members
  .p3
  span:first-child {
  font-weight: bold;
  padding-right: 10px;
}

.services-detail-page .content-wrap .content-left .members .p4 {
  font-size: 0.8em;
}

.services-detail-page .toggle-button-wrap {
  padding: 80px 0px 80px 260px;
}

.services-detail-page .toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  color: #999999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 16px;
  position: relative;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
}

.services-detail-page .toggle-button img {
  width: 20px;
  margin-left: 10px;
}

.services-detail-page .toggle-button::before,
.services-detail-page .toggle-button::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 2px;
  background-color: #e0e0e0;
}

.services-detail-page .toggle-button::before {
  left: 0;
}

.services-detail-page .toggle-button::after {
  right: 0;
}

.services-detail-page .toggle-button .full-line::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 10px;
}

.services-detail-page
  .content-wrap
  .content-left
  .members
  .p4
  span:first-child {
  font-weight: bold;
  padding-right: 10px;
}

.services-detail-page .recent-news {
  margin: 0px 300px 60px 300px;
}

.services-detail-page .recent-news h1 {
  line-height: 40px;
  font-size: 40px;
  font-weight: 500;
  color: #333;
}

.services-detail-page .recent-news .swiper-wrapper {
  padding-bottom: 24px;
}

.services-detail-page .recent-news .newsletter-content {
  border: 3px solid #d9d9d9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 301px;
  min-height: 230px;
  max-height: 230px;
  box-sizing: border-box;
}

.services-detail-page .recent-news .newsletter-content:hover {
  border: 3px solid #1676bc;
}

.services-detail-page .recent-news .mark {
  text-align: left;
  height: 40px;
  align-items: center;
  display: flex;
}

.services-detail-page .recent-news .mark span {
  background-color: #1676bc;
  color: white;
  padding: 8px;
  font-size: 17px;
  align-items: center;
  line-height: 18px;
  font-weight: bold;
}

.services-detail-page .recent-news .title {
  height: 100px;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  margin: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20.8px;
  color: #333333;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.services-detail-page .recent-news .bottom {
  height: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 16px;
  color: #888;
  align-items: center;
}

.landing-page .newsletter-section .newsletter-content .bottom .date {
  font-size: 16px;
  line-height: 16px;
  color: #999999;
  font-weight: 400;
}

.services-detail-page .recent-news .bottom a {
  text-decoration: none;
  font-weight: 500;
  color: #999999;
}

.services-detail-page .recent-news .bottom .more {
  text-decoration: underline;
  cursor: pointer;
}

.services-detail-page .recent-news .swiper-scrollbar-drag {
  background: #1676bc;
}

.services-detail-page .content-wrap .content-right {
  position: sticky;
  top: 72px;
  padding-right: 10%;
  margin-left: 5%;
  min-width: 240px;
  padding-top: 48px;
}

.services-detail-page .content-wrap .content-right p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 26.5px;
  color: #999999;
  cursor: pointer;
  font-weight: 300;
}

.services-detail-page .content-wrap .content-right p span {
  font-size: 14px;
  line-height: 14px;
  color: #999999;
}

.services-detail-page .content-wrap .content-right p:hover {
  color: #1676bc;
}

.services-detail-page .recent-news .swiper-container {
  padding: 0;
  margin: 28px auto;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1232px;
}

.services-detail-page .recent-news .swiper-scrollbar {
  position: static;
  width: 100%;
}

.services-detail-page .recent-news .more {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 5px;
  margin: 10px;
}

.services-detail-page .recent-news p {
  margin-top: 80px;
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
}

.services-detail-page .recent-news .more img {
  width: 50px;
  margin-left: 10px;
}

.services-detail-page .recent-news .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.services-detail-page .recent-news .nav-button.prev {
  left: -80px;
}

.services-detail-page .recent-news .nav-button.next {
  right: -80px;
}

/* 구성원 페이지 */

.members-page {
  padding-top: 72px;
}

.members-page .process {
  display: flex;
  align-items: center;
  margin: 100px 300px 100px 300px;
}

.members-page .process img {
  width: 18px;
}

.members-page .process span {
  font-size: 18px;
  line-height: 18px;
  padding-left: 10px;
  color: #999999;
}

.members-page .process .search {
  display: none;
}
.members-page .title-section .sub-title {
  margin: 100px 300px 0px 300px;
}

.members-page .title-section .sub-title p {
  font-size: 5em;
}

.members-page .title-section .sub-title p span {
  color: #1676bc;
  font-size: 1.625em;
  line-height: 130px;
}

.members-page .search-section {
  background-color: #f9f9f9;
  padding: 40px 0;
}

.members-page .search-bar-wrap {
  position: relative;
  margin: 0 auto;
  max-width: 653px;
}

.members-page .search-bar-wrap .autocomplete-suggestions {
  position: absolute;
  background-color: #fff;
  padding: 5px 10px;
  width: 100%;
  max-width: 500px;
  margin: 0;
  z-index: 1000;
}

.members-page .search-bar-wrap .autocomplete-suggestions li {
  cursor: pointer;
  padding: 5px 10px;
}

.members-page .search-bar-wrap .autocomplete-suggestions li:hover {
  background-color: #f3f4f8;
}

.members-page .search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  background-color: white;
  padding: 20px 24px;
  height: 56px;
  box-sizing: border-box;
}

.members-page .search-bar input {
  flex: 6;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #111;
  outline: none;
  background: transparent;
}

.members-page .search-bar img {
  flex: 1;
  height: 24px;
  padding-top: 3px;
  cursor: pointer;
}

.members-page .title-section .search-category-wrap {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0 0 0;
  justify-content: space-between;
}

.members-page .title-section .search-category-wrap button {
  border: 2px solid #1676bc;
  border-radius: 20px;
  color: #1676bc;
  background-color: white;
  padding: 7px 16px;
  cursor: pointer;
  font-weight: 500;
}

.members-page .title-section .search-category-wrap button#advisor {
  border: 2px solid #5ebaff;
  border-radius: 20px;
  color: #5ebaff;
  background-color: white;
  padding: 7px 16px;
  cursor: pointer;
  font-weight: 500;
}

.members-page .title-section .search-category-wrap .active {
  background-color: #1676bc;
  color: white;
}

.members-page .title-section .search-category-wrap #advisor-active {
  background-color: #5ebaff;
  color: white;
  border: 2px solid #5ebaff;
}

.members-page .title-section .search-category-wrap button:hover {
  background-color: #1676bc;
  color: white;
}

.members-page .title-section .search-category-wrap button#advisor:hover {
  background-color: #5ebaff;
  color: white;
}

.members-page .title-section .search-category-wrap button span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

/* 구성원 */

.members-page .hired-title {
  margin: 5% 10% 2% 10%;
}

.members-page .hired-title span {
  font-size: 1.5em;
}

.members-page .hired-section .swiper-wrapper {
  padding: 125px 260px;
}

.members-page .hired {
  background-color: #f9f9f9;
}

.members-page .hired-section .swiper-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.members-page .hired-section .swiper-slide > div .img-wrap {
  flex: 3;
}

.members-page .hired-section .swiper-slide > div .img-wrap img {
  max-width: 80%;
}

.members-page .hired-section .swiper-slide > div .text-wrap {
  flex: 3;
  text-align: left;
  padding: 20px;
}

.members-page .hired-section .swiper-slide > div .text-wrap .job {
  margin: 0px;
  font-weight: bold;
}

.members-page .hired-section .swiper-slide > div .text-wrap .name {
  font-size: 1.5em;
  font-weight: bold;
  color: #1676bc;
}

.members-page .hired-section .swiper-slide > div .text-wrap .name-2 {
  padding-left: 10px;
  font-size: 1.2em;
  color: #888;
}

.members-page .hired-section .swiper-slide > div .text-wrap .pre {
  font-weight: bold;
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.members-page .hired-section .swiper-slide > div .text-wrap .pre img {
  width: 20px;
  padding-right: 5px;
}

.members-page .hired-section .swiper-slide > div .text-wrap .viewmore img {
  width: 108px;
  cursor: pointer;
}

.members-page .hired-section .swiper-slide > div .gap {
  flex: 4;
}

.members-page .hired-section .swiper-slide > div .back {
  position: absolute;
  bottom: 0;
  right: 30%;
  color: white;
  font-size: 15em;
  font-weight: bold;
  z-index: -1;
}

/* 구성원 - 변호사 */

.members-page .lawyer-section {
  padding: 50px 300px 200px 300px;
  text-align: center;
}

.members-page .lawyer-section .grid-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
  justify-items: center;
  gap: 246px 0;
}

.members-page .lawyer-section .lawyer-item:first-child {
  justify-self: center;
}

.members-page .lawyer-section .lawyer-item {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  position: relative;
  cursor: pointer;
}

.members-page .lawyer-section .lawyer-item img {
  max-width: 186px;
}
.members-page .lawyer-section .lawyer-item .text-wrap {
  position: absolute;
  text-align: left;
  height: 246px;
}

.members-page .lawyer-section .lawyer-item .text-wrap .p1 {
  font-size: 22px;
  line-height: 28px;
  margin-top: 20px;
  color: #333;
  font-weight: 400;
}

.members-page .lawyer-section .lawyer-item .text-wrap .p1 span:last-child {
  color: #999999;
}

.members-page .lawyer-section .lawyer-item .text-wrap .p2 {
  color: #999999;
  font-size: 16px;
  line-height: 16px;
  margin-left: 10px;
}

.members-page .lawyer-section .lawyer-item .text-wrap .class-wrap {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.members-page .lawyer-section .lawyer-item .text-wrap .p3 {
  border: 1px solid #999999;
  color: #666666;
  font-size: 14px;
  line-height: 14px;
  width: fit-content;
  padding: 2px 4px;
}

.members-page .lawyer-section .selected {
  filter: none;
}

.members-page .lawyer-section .lawyer-item:hover {
  filter: none;
}

.members-page .lawyer-section .lawyer-item .text-wrap {
  position: absolute;
  text-align: left;
  transition: opacity 0.3s ease;
}
.members-page .lawyer-section .lawyer-item.selected .text-wrap {
  opacity: 1;
  visibility: visible;
}

.members-page .lawyer-section .toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px auto;
  color: #999999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: relative;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
}

.members-page .lawyer-section .toggle-button img {
  width: 20px;
  margin-left: 10px;
}

.members-page .lawyer-section .toggle-button::before,
.members-page .lawyer-section .toggle-button::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 2px;
  background-color: #e0e0e0;
}

.members-page .lawyer-section .toggle-button::before {
  left: 0;
}

.members-page .lawyer-section .toggle-button::after {
  right: 0;
}

.members-page .lawyer-section .toggle-button .full-line::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  margin-top: 10px;
}

/* 구성원 검색 페이지 */

.member-search-page {
  padding-top: 72px;
}

.member-search-page .process {
  display: flex;
  align-items: center;
  margin: 125px 260px;
}

.member-search-page .process img {
  width: 24px;
}

.member-search-page .process span {
  font-size: 1.5em;
  padding-left: 15px;
  color: #999999;
}

.member-search-page .title-section .process .search {
  display: block;
}

.member-search-page .title-section .sub-title {
  margin: 125px 260px;
}

.member-search-page .search-bar-wrap {
  background-color: #f9f9f9;
  padding: 5% 25%;
}

.member-search-page .search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  background-color: white;
}

.member-search-page .search-bar input {
  flex: 6;
  border: none;
  padding: 10px;
  font-size: 0.8em;
  outline: none;
  background: transparent;
}

.member-search-page .search-bar img {
  flex: 1;
  height: 20px;
  padding-right: 10px;
  padding-top: 3px;
  cursor: pointer;
}

.member-search-page .search-list-section {
  margin: 5% 10% 10% 10%;
}

.member-search-page .search-list-section .search-list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}

.member-search-page .search-list-section .lawyer {
  flex: 0 1 18%;
}

.member-search-page .search-list-section .lawyer img {
  width: 100%;
}

.member-search-page .search-list-section .lawyer .text-wrap p {
  margin: 0px;
}

.member-search-page .search-list-section .lawyer .text-wrap .name {
  font-size: 1.5em;
}

.member-search-page .search-list-section .lawyer .text-wrap .name > span {
  font-size: 0.7em;
  color: #888;
  padding-left: 10px;
}

.member-search-page .search-list-section .lawyer .text-wrap .job {
  color: #888;
  margin: 10px 0;
}

.member-search-page .search-list-section .lawyer .text-wrap .phone > span {
  font-weight: bold;
  padding-right: 10px;
}

.member-search-page .search-list-section .lawyer .text-wrap .email > span {
  font-weight: bold;
  padding-right: 10px;
}

.member-search-page .search-list-section .pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-search-page .search-list-section .page-number {
  margin: 0 5px;
  cursor: pointer;
  color: #555;
}

.member-search-page .search-list-section .page-number.active {
  font-weight: bold;
  color: #007bff;
}

.member-search-page .search-list-section .separator {
  margin: 0 5px;
  color: #bbb;
}

.member-search-page .title-section .search-category-wrap {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5% 0;
}

.member-search-page .search-bar-wrap .autocomplete-suggestions {
  position: absolute;
  background-color: #fff;
  padding: 5px 10px;
  width: 100%;
  max-width: 500px;
  margin: 0;
  z-index: 1000;
}

.member-search-page .search-bar-wrap .autocomplete-suggestions li {
  cursor: pointer;
  padding: 5px 10px;
}

.member-search-page .search-bar-wrap .autocomplete-suggestions li:hover {
  background-color: #f3f4f8;
}

.member-search-page .title-section .search-category-wrap button {
  width: fit-content;
  border: 1px solid #1676bc;
  border-radius: 25px;
  color: #1676bc;
  background-color: white;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
}

.member-search-page .title-section .search-category-wrap .active {
  background-color: #1676bc;
  color: white;
}

.member-search-page .title-section .search-category-wrap button:hover {
  background-color: #1676bc;
  color: white;
}

/* 구성원 상세 페이지 */

.member-profile-page .recent-news .more {
  border: none;
  background: none;
  padding-left: 10px;
  cursor: pointer;
}

.member-profile-page .recent-news .more img {
  width: 50px;
}

.member-profile-page .recent-news {
  margin: 0px 300px 0px 300px;
}

.member-profile-page .recent-news h1 {
  line-height: 22px;
  font-size: 22px;
  font-weight: 500;
  color: #333;
  margin-bottom: 40px;
  border-left: #1676bc 5px solid;
  padding-left: 20px;
  display: inline-block;
}

.member-profile-page .recent-news .swiper-wrapper {
  padding-bottom: 24px;
}

.member-profile-page .recent-news .swiper-container {
  padding: 0;
  margin: 28px auto;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1232px;
}

.member-profile-page .recent-news .swiper-scrollbar {
  position: static;
  width: 100%;
}

.member-profile-page .recent-news .newsletter-content {
  box-sizing: border-box;
  border: 3px solid #d9d9d9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 301px;
  min-width: 160px;
  min-height: 125px;
  max-height: 230px;
}

.member-profile-page .recent-news .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.member-profile-page .recent-news .nav-button.prev {
  left: -80px;
}

.member-profile-page .recent-news .nav-button.next {
  right: -80px;
}

.member-profile-page .recent-news .newsletter-content:hover {
  border: 3px solid #1676bc;
}

.member-profile-page .recent-news .mark {
  text-align: left;
  height: 40px;
  align-items: center;
  display: flex;
}

.member-profile-page .recent-news .mark span {
  background-color: #1676bc;
  color: white;
  padding: 8px;
  font-size: 17px;
  align-items: center;
  line-height: 17px;
  font-weight: bold;
}

.member-profile-page .recent-news .title {
  height: 100px;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  margin: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20.8px;
  color: #333333;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.member-profile-page .recent-news .bottom {
  height: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 16px;
  color: #888;
  align-items: center;
}

.member-profile-page .recent-news .bottom .date {
  font-size: 16px;
  line-height: 16px;
  color: #999999;
  font-weight: 400;
}

.member-profile-page .recent-news .bottom a {
  text-decoration: none;
  font-weight: 500;
  color: #999999;
}

.member-profile-page .recent-news .bottom .more {
  text-decoration: underline;
  cursor: pointer;
}

.member-profile-page .recent-news .swiper-scrollbar-drag {
  background: #1676bc;
}

.member-profile-page {
  padding-top: 72px;
}

.member-profile-page .title-section {
  display: flex;
  justify-content: space-between;
  margin: 100px 300px 100px 300px;
}

.member-profile-page .process {
  display: flex;
  align-items: center;
}

.member-profile-page .process img {
  width: 18px;
}

.member-profile-page .process span {
  font-size: 18px;
  line-height: 18px;
  padding-left: 10px;
  color: #999999;
}

.member-profile-page .save-wrap {
  display: flex;
  justify-content: flex-end;
}

.member-profile-page .save {
  display: flex;
  align-items: center;
  padding: 18px;
  border: 2px solid #1676bc;
  box-sizing: border-box;
  width: fit-content;
  margin: 0 300px;
}

.member-profile-page .save img {
  width: 24px;
  cursor: pointer;
  padding-right: 8px;
  line-height: 24px;
}

.member-profile-page .save span {
  cursor: pointer;
  color: #1676bc;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.member-profile-page .profile-main {
  margin: 40px 300px 0px 300px;
}

.member-profile-page .profile-main .content-wrap {
  display: flex;
  justify-content: space-between;
}

.member-profile-page .profile-main .text-wrap {
  max-width: 730px;
}

.member-profile-page .profile-main .text-wrap .name {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #333333;
}

.member-profile-page .profile-main .text-wrap .name .job {
  color: #999999;
  font-weight: 500;
  font-size: 18px;
  padding-left: 20px;
  line-height: 18px;
}

.member-profile-page .profile-main .text-wrap .mark {
  display: flex;
  align-items: center;
}

.member-profile-page .profile-main .text-wrap .mark img {
  width: 24px;
  padding-right: 5px;
}

.member-profile-page .profile-main .text-wrap .sub-title01 {
  margin: 0;
  border-left: #1676bc 5px solid;
  margin-top: 84px;
  margin-bottom: 20px;
}

.member-profile-page .profile-main .text-wrap .sub-title01 span {
  padding-left: 20px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.member-profile-page .profile-main .text-wrap .sub-content01 {
  margin-bottom: 40px;
}

.member-profile-page .profile-main .text-wrap .sub-content01 p {
  border: 1px solid #666666;
  padding: 10px 20px;
  margin: 8px;
  white-space: nowrap;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #666666;
  width: fit-content;
}

.member-profile-page .profile-main .text-wrap .sub-title02 {
  margin: 0;
  border-left: #1676bc 5px solid;
  margin-bottom: 10px;
}

.member-profile-page .profile-main .text-wrap .sub-content01 p {
  font-size: 18px;
  line-height: 18px;
  color: #666666;
}

.member-profile-page .profile-main .text-wrap .sub-title02 span {
  padding-left: 20px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.member-profile-page .profile-main .text-wrap .sub-content01 {
  margin-left: 20px;
}

.member-profile-page .profile-main .text-wrap .sub-content02 {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 20px;
}

.member-profile-page .profile-main .text-wrap .sub-content02 span {
  border: 1px solid #666666;
  padding: 10px 20px;
  margin: 8px;
  white-space: nowrap;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #666666;
}

.member-profile-page .sub-menu {
  margin: 0px 300px 50px 300px;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #d9d9d9;
  position: sticky;
  top: 72px;
  background-color: white;
  z-index: 1;
}

.member-profile-page .sub-menu .menu-item {
  color: #666;
  cursor: pointer;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}

.member-profile-page .sub-menu .menu-item.active {
  color: #1676bc;
  font-weight: bold;
}

.member-profile-page .profile-main .img-wrap {
  min-width: 460px;
  width: 460px;
  margin-top: 60px;
}

.member-profile-page .profile-main .img-wrap img {
  width: 100%;
}

.member-profile-page .profile-main .ment {
  padding: 50px 25px;
  line-height: 30px;
  font-size: 18px;
  color: #333;
  max-width: 705px;
  white-space: break-spaces;
}

.member-profile-page .fold-section {
  margin: 0 300px;
  padding-bottom: 50px;
}

.member-profile-page .fold-section > div {
  padding-bottom: 20;
}

.member-profile-page .fold-section > div .title {
  font-size: 1.5em;
  font-weight: bold;
  border-left: #1676bc 5px solid;
}

.member-profile-page .fold-section > div .title p {
  padding-left: 20px;
  font-size: 22px;
  line-height: 22px;
}

.member-profile-page .fold-section .content-wrap {
  padding: 10px 0px 30px 0px;
}

.member-profile-page .fold-section > div .content {
  margin: 0 20px;
  display: flex;
  width: 100%;
  margin: 20px;
}

.member-profile-page .fold-section > div .content p {
  position: relative;
  color: #111;
  font-size: 18px;
  line-height: 40px;
  align-content: center;
  width: 80%;
}

.member-profile-page .fold-section > div .content span {
  position: relative;
  color: #111;
  font-size: 18px;
  line-height: 40px;
  align-content: center;
}

.member-profile-page .fold-section > div .content > div {
  margin: 20px 0;
  text-align: left;
  color: #666666;
}

.member-profile-page .fold-section > div .content .year-wrap {
  display: flex;
  align-items: start;
  position: relative;
  width: 20%;
  gap: 3px;
  margin: 0;
}

.member-profile-page .fold-section > div .content .year {
  color: #999999;
}

.member-profile-page .fold-section button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  color: #999999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
  line-height: 18px;
  position: relative;
  width: 100%;
  font-weight: 700;
}

.member-profile-page .fold-section button img {
  width: 18px;
  margin-left: 10px;
}

.member-profile-page .fold-section button::before,
.member-profile-page .fold-section button::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 2px;
  background-color: #e0e0e0;
}

.member-profile-page .fold-section button::before {
  left: 0;
}

.member-profile-page .fold-section button::after {
  right: 0;
}

.member-profile-page .fold-section .full-line::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 10px;
}

.member-profile-page .info {
  margin: 0 300px 100px 0;
  text-align: right;
}

.member-profile-page .info .phone {
  color: #999999;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 20px;
}

.member-profile-page .info .phone > span {
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding-right: 25px;
}

.member-profile-page .info .email {
  color: #999999;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.member-profile-page .info .email > span {
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  padding-right: 25px;
}

.member-profile-page .top-btn {
  position: fixed;
  bottom: 5%;
  right: 5%;
  border: none;
  cursor: pointer;
  background: none;
}

.member-profile-page .top-btn img {
  width: 24px;
}

/* 법인소식 */

.news-page {
  padding-top: 72px;
  text-align: center;
}

.news-page .process {
  display: flex;
  align-items: center;
  margin: 100px 300px;
}

.news-page .process img {
  width: 18px;
}

.news-page .process span {
  font-size: 18px;
  line-height: 18px;
  padding-left: 10px;
  color: #999999;
}

.news-page .title {
  margin: 100px 300px;
  font-family: "Times New Roman", Times, serif;
  text-align: left;
}

.news-page .title p {
  font-size: 5em;
  margin: 0px;
}

.news-page .title p span {
  font-size: 1.25em;
  color: #1676bc;
}

.news-page .theme-select {
  margin: 100px 300px 0 0px;
  display: flex;
  justify-content: flex-end;
  gap: 140px;
  align-items: center;
}

.news-page .theme-select-wrap {
  display: flex;
  gap: 40px;
}

.news-page .theme-select button {
  background: none;
  border: none;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 0;
  width: fit-content;
}

.news-page .theme-select span.active {
  font-weight: bold;
  color: black;
  border-bottom: 4px solid black;
  padding-bottom: 10px;
}

.news-page .theme-select button:hover {
  color: black;
  font-weight: bold;
}

.news-page .search-bar {
  height: 56px;
  width: 357px;
  position: relative;
}

.news-page .search-bar .search-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
}

.news-page .search-input {
  height: 56px;
  width: 357px;
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 40px 0 24px;
  border: 2px solid #999;
}

.news-page .news-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 30px;
  padding: 50px 300px 100px 300px;
}

.news-page .news-item {
  width: 420px;
  height: 545px;
  max-width: 420px;
  max-height: 545px;
  border: 3px solid #d9d9d9;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  flex: 30%;
  display: flex;
  flex-direction: column;
}

.news-page .news-item img {
  width: 100%;
  height: 414px;
  object-fit: cover;
  object-position: center;
}

.news-page .news-content {
  padding: 20px;
  height: 100%;
}

.news-page .news-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.news-page .news-title {
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 26px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 52px;
}

.news-page .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: right;
}

.news-page .news-date {
  color: #999999;
  font-size: 16px;
  line-height: 16px;
}

.news-page .more > a {
  font-size: 20px;
  line-height: 26px;
  color: #333;
  text-decoration: underline;
}

.news-page .press-grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 30px;
  row-gap: 20px;
  padding: 50px 300px 100px 300px;
}

.news-page .press-item {
  width: 640px;
  height: 136px;
  border: 3px solid #dbdbdb;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.news-page .press-item:hover {
  border: 3px solid #1676bc;
}

.news-page .press-item label {
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  background-color: #1676bc;
  padding: 8px;
  display: inline-block;
  font-weight: bold;
}

.news-page .press-item h2 {
  text-align: left;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 600px;
}

.news-page .pagination {
  margin: 0 0 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-page .pagination img {
  width: 24px;
  cursor: pointer;
}

.news-page .pagination .number-wrap {
  padding: 0 14px;
}

.news-page .pagination .page-number {
  cursor: pointer;
  color: #999999;
  padding: 0 4px;
  font-weight: bold;
}

.news-page .pagination .page-number.active {
  font-weight: bold;
  color: #1676bc;
}

.news-page .pagination span {
  font-size: 20px;
  line-height: 26px;
}

.news-page .pagination .separator {
  color: #d9d9d9;
  padding: 4px 15px;
}

/* 뉴스 게시글 페이지 */

.news-post-page {
  padding-top: 72px;
  text-align: center;
}

.news-post-page .process {
  display: flex;
  align-items: center;
  margin: 100px 300px;
}

.news-post-page .process img {
  width: 18px;
}

.news-post-page .process span {
  font-size: 18px;
  line-height: 18px;
  padding-left: 10px;
  color: #999999;
}

.news-post-page .title {
  margin: 100px 300px 50px 300px;
  text-align: left;
}

.news-post-page .title h1 {
  font-size: 28px;
  line-height: 36.4px;
  margin: 0;
}

.news-post-page .section1 {
  margin: 50px 300px 20px 300px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 18px;
  color: #888;
}

.news-post-page .section1 .date {
  font-size: 18px;
  line-height: 18px;
}

.news-post-page .section1 div {
  display: flex;
  align-items: center;
  color: #666666;
}

.news-post-page .section1 img {
  width: 20px;
  padding: 0 10px 0 20px;
}

.news-post-page .content {
  margin: 20px 300px;
  text-align: center;
  max-width: 1320px;
}

.news-post-page .content img {
  width: 50%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 50px;
}

.news-post-page .content .sub-title {
  font-size: 0.9em;
  color: #666666;
  font-weight: bold;
}

.news-post-page .content p {
  text-align: left;
  font-size: 18px;
  line-height: 30px;
}

.news-post-page .related-news {
  position: relative;
  padding-top: 12px;
  text-align: left;
  border-top: 2px solid #ddd;
}

.news-post-page .related-news .list {
  position: absolute;
  right: 0;
  font-size: 1em;
  display: flex;
  text-align: right;
}

.news-post-page .related-news a {
  display: flex;
  align-items: center;
}

.news-post-page .related-news .list img {
  width: 24px;
  padding: 0 12px 0 20px;
}

.news-post-page .related-news .list span {
  font-size: 18px;
  line-height: 18px;
  color: #666666;
  font-weight: 500;
}

.news-post-page .related-news h3 {
  font-size: 20px;
  line-height: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.news-post-page .related-news-wrap {
  margin: 48px 300px 100px 300px;
}

.news-post-page .related-news-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px 30px;
  justify-content: space-between;
}

.news-post-page .related-news-list .list {
  display: block;
  border-bottom: 1px solid #ddd;
  width: 100%;
  text-align: left;
}

.news-post-page .related-news-list .list h1 {
  font-size: 16px;
  line-height: 20.8px;
  margin: 0;
}

.news-post-page .related-news-list .list span {
  font-size: 14px;
  line-height: 14px;
  color: #a2a9b1;
  margin: 10px 0;
  display: block;
}

.news-post-page .related-news-wrap .swiper-container {
  position: relative;
}

.news-post-page .related-news-wrap .swiper-slide {
  border: 3px solid #ddd;
  height: 364px;
  /* max-width: 457px; */
  /* max-height: 364px; */
}

.news-post-page .related-news-wrap .swiper-slide .content {
  margin: 0;
}

.news-post-page .related-news-wrap .swiper-slide .content .img-wrap {
  overflow: hidden;
}

.news-post-page .related-news-wrap .swiper-slide .content .img-wrap img {
  height: 160px;
}

.news-post-page .related-news-wrap .swiper-slide .content .text-wrap {
  padding: 20px;
  height: 160px;
}

.news-post-page .related-news-wrap .swiper-slide .content .text-wrap .title {
  height: 140px;
  margin: 0;
  font-size: 1.75em;
}

.news-post-page .related-news-wrap .swiper-slide .content .text-wrap .bottom {
  display: flex;
  font-size: 1.25em;
  justify-content: space-between;
  color: #999999;
}

.news-post-page
  .related-news-wrap
  .swiper-slide
  .content
  .text-wrap
  .bottom
  .more {
  text-decoration: underline;
  color: #888;
  cursor: pointer;
}

.news-post-page .related-news-wrap .swiper-scrollbar-drag {
  background: #1676bc;
}

.news-post-page .related-news-wrap .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.news-post-page .related-news-wrap .nav-button.prev {
  left: -80px;
}

.news-post-page .related-news-wrap .nav-button.next {
  right: -80px;
}

.news-post-page .related-news-wrap .nav-button img {
  width: 48px;
}

/* 뉴스레터 */

.newsletter-page {
  padding-top: 72px;
  margin: 125px 260px;
}

.newsletter-page .process {
  display: flex;
  align-items: center;
}

.newsletter-page .process img {
  width: 24px;
}

.newsletter-page .process span {
  font-size: 1.5em;
  padding-left: 15px;
  color: #999999;
}

.newsletter-page .title {
  margin: 5% 0;
  text-align: left;
}

.newsletter-page .title p {
  font-size: 5em;
}

.newsletter-page .title p span {
  font-size: 1.25em;
  color: #1676bc;
}

.newsletter-page .card-wrap {
  display: flex;
  gap: 20px;
  width: 100%;
}

.newsletter-page .newsletter-content {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  max-width: 20.5%;
  min-width: 20.5%;
  padding: 44px 28px 28px 28px;
}

.newsletter-page .newsletter-content .mark {
  height: 40px;
}

.newsletter-page .newsletter-content .mark span {
  background-color: #1676bc;
  color: white;
  padding: 2px 5px;
  font-size: 1.5em;
}

.newsletter-page .newsletter-content .title {
  font-weight: bold;
  text-align: left;
  font-size: 1.5em;
  height: 200px;
}

.newsletter-page .newsletter-content .bottom {
  flex: 2;
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: #888;
}

.newsletter-page .newsletter-content .bottom a {
  text-decoration: underline;
  color: #888;
}

.newsletter-page .list-wrap {
  margin-top: 50px;
  padding: 20px 0;
  border-top: 3px solid #d9d9d9;
}

.newsletter-page .list-wrap .list {
  padding: 48px;
  border-bottom: 1px solid #d9d9d9;
}

.newsletter-page .list-wrap .list .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newsletter-page .list-wrap .list .top .mark {
  background-color: #1676bc;
  color: white;
  padding: 8px;
  font-size: 1.25em;
}

.newsletter-page .list-wrap .list .bottom span {
  font-size: 1.5em;
  font-weight: bold;
}

.newsletter-page .list-wrap .list .date {
  color: #999999;
  font-size: 1.25em;
}

.newsletter-page .list-wrap .list .bottom {
  margin-top: 16px;
}

.newsletter-page .pagination {
  margin: 0 0 10% 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter-page .pagination img {
  width: 24px;
  cursor: pointer;
}

.newsletter-page .pagination .page-number {
  margin: 0 5px;
  cursor: pointer;
  color: #555;
}

.newsletter-page .pagination .page-number.active {
  font-weight: bold;
  color: #007bff;
}

.newsletter-page .pagination .separator {
  margin: 0 5px;
  color: #bbb;
}

/* 뉴스레터 포스트 페이지  */

/* 뉴스 게시글 페이지 */

.newsletter-post-page {
  padding-top: 72px;
  text-align: center;
}

.newsletter-post-page .process {
  display: flex;
  align-items: center;
  margin: 125px 260px;
}

.newsletter-post-page .process img {
  width: 24px;
}

.newsletter-post-page .process span {
  font-size: 1.5em;
  padding-left: 15px;
  color: #999999;
}

.newsletter-post-page .title {
  margin: 125px 260px;
  text-align: left;
}

.newsletter-post-page .title h1 {
  font-size: 3em;
  margin: 0;
}

.newsletter-post-page .section1 {
  margin: 125px 260px;
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  color: #888;
}

.newsletter-post-page .section1 .date {
  font-size: 1.125em;
}

.newsletter-post-page .section1 div {
  display: flex;
  align-items: center;
  color: #666666;
}

.newsletter-post-page .section1 img {
  width: 20px;
  padding: 0 10px 0 20px;
}

.newsletter-post-page .content {
  margin: 125px 260px;
  text-align: center;
}

.newsletter-post-page .content img {
  width: 100%;
  object-fit: fill;
  object-position: center;
}

.newsletter-post-page .content .sub-title {
  font-size: 0.9em;
  color: #666666;
  font-weight: bold;
}

.newsletter-post-page .content p {
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  margin: 20px 0;
}

.newsletter-post-page .related-news {
  margin: 0 260px;
  padding-top: 10px;
  text-align: left;
  border-top: 2px solid #ddd;
}

.newsletter-post-page .related-news .list {
  position: absolute;
  right: 260px;
  font-size: 1em;
  padding-top: 5px;
  display: flex;
  text-align: right;
}

.newsletter-post-page .related-news a {
  display: flex;
  align-items: center;
}

.newsletter-post-page .related-news .list img {
  width: 20px;
  padding: 0 10px 0 20px;
}

.newsletter-post-page .related-news h3 {
  font-size: 1.5em;
  padding-top: 40px;
}

.newsletter-post-page .swiper {
  /* margin: 125px 260px; */
}

.newsletter-post-page .swiper-slide {
  border: 1px solid #ddd;
}

.newsletter-post-page .swiper-slide .content {
  margin: 0;
}

.newsletter-post-page .swiper-slide .content .img-wrap {
  height: 150px;
  overflow: hidden;
}

.newsletter-post-page .swiper-slide .content .text-wrap {
  padding: 10px;
}

.newsletter-post-page .swiper-slide .content .text-wrap .title {
  height: 80px;
  margin: 0;
  font-size: 1em;
}

.newsletter-post-page .swiper-slide .content .text-wrap .bottom {
  display: flex;
  font-size: 0.8em;
  justify-content: space-between;
}

.newsletter-post-page .swiper-slide .content .text-wrap .bottom .more {
  text-decoration: underline;
  color: #888;
  cursor: pointer;
}

.newsletter-post-page .related-news-wrap .swiper-container {
  margin: 24px 260px 80px 260px;
  position: relative;
  display: flex;
  align-items: center;
}

.newsletter-post-page .related-news-wrap .swiper-wrapper {
  padding: 5% 0;
}

.newsletter-post-page .related-news-wrap .swiper-wrapper .newsletter-content {
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  padding: 44px 28px 28px 28px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.newsletter-post-page
  .related-news-wrap
  .swiper-wrapper
  .newsletter-content
  .mark {
  text-align: left;
  height: 40px;
}

.newsletter-post-page
  .related-news-wrap
  .swiper-wrapper
  .newsletter-content
  .mark
  span {
  background-color: #1676bc;
  color: white;
  width: 100%;
  padding: 5px 10px;
  font-size: 1.5em;
}

.newsletter-post-page
  .related-news-wrap
  .swiper-wrapper
  .newsletter-content
  .title {
  font-weight: bold;
  text-align: left;
  font-size: 1.5em;
  margin: 0;
  height: 200px;
}

.newsletter-post-page
  .related-news-wrap
  .swiper-wrapper
  .newsletter-content
  .bottom {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: #888;
  height: 50px;
}

.newsletter-post-page
  .related-news-wrap
  .swiper-wrapper
  .newsletter-content
  .bottom
  a {
  text-decoration: none;
  color: #888;
}

.post-page
  .related-news-wrap
  .swiper-wrapper
  .newsletter-content
  .bottom
  .more {
  text-decoration: underline;
  cursor: pointer;
}

.newsletter-post-page .related-news-wrap .swiper-scrollbar-drag {
  background: #1676bc;
}

.newsletter-post-page .related-news-wrap .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.newsletter-post-page .related-news-wrap .nav-button.prev {
  left: -60px;
}

.newsletter-post-page .related-news-wrap .nav-button.next {
  right: -60px;
}

.newsletter-post-page .related-news-wrap .nav-button img {
  width: 36px;
}

/* 센터페이지 기업감사/내부통제 지원센터 */

.support-center-page {
  position: relative;
  text-align: center;
  padding-top: 72px;
}

.support-center-page .main {
  position: relative;
  height: 892px;
  background:
  linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255) 100%
  ),
  url("../src/assets/images/CenterMain01.png");
  background-size: cover;
  background-position: center;
}

.support-center-page .main .img-wrap {
  position: relative;
  width: 100%;
  height: auto;
}

.support-center-page .main .img-wrap img {
  width: 100%;
  height: auto;
}

.support-center-page .main .text-wrap {
  position: absolute;
  top: 60%;
  left: 40%;
  transform: translate(-50%, -50%);
  text-align: left;
  z-index: 100;
}

.support-center-page .main .text-wrap p {
  font-size: 60px;
  font-weight: bold;
}

.support-center-page .main .text-wrap h1 {
  font-size: 100px;
  font-weight: bold;
  margin: 0;
}

.support-center-page .theme-select {
  position: sticky;
  top: 72px;
  background-color: white;
  z-index: 100;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.support-center-page .theme-select button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 5px;
  margin: 10px;
}

.support-center-page .theme-select button.active {
  font-weight: bold;
  color: black;
}

.support-center-page .theme-select button.active::after {
  border-bottom: 4px solid black;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.support-center-page .theme-select button:hover {
  color: black;
  font-weight: bold;
}

.support-center-page > .section01 {
  position: relative;
}

.support-center-page > .section01 .img-wrap {
  z-index: 1;
  margin: 0 10%;
}

.support-center-page > .section01 img {
  width: 100%;
  max-width: 600px;
  height: auto;
  text-align: left;
}

.support-center-page > .section01 .text-wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}

.support-center-page > .section01 .text-wrap .p1 {
  font-weight: bold;
  font-size: 1.2em;
}

.support-center-page > .section01 .text-wrap h1 {
  font-size: 40px;
  margin: 28px 0;
}

.indentation {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.support-center-page > .section01 .text-wrap .p2 {
  font-size: 20px;
  line-height: 30px;
}

.support-center-page > .sub-title {
  width: 100%;
  font-size: 9em;
  color: #eeeeee;
  font-weight: normal;
  text-align: center;
}

.support-center-page .section02 {
  margin: 0 5%;
}

.support-center-page .section02 .content {
  display: flex;
  margin: 5% auto;
  background-color: linear-gradient(to top, #fafafa, #fafafa00);
  max-width: 1320px;
  justify-content: space-between;
}

.support-center-page .section02 .content .text-wrap .p1 {
  font-weight: bold;
  font-size: 35px;
  line-height: 52px;
  margin-bottom: 40px;
}

.support-center-page .section02 .content .text-wrap .p2 {
  font-size: 18px;
  margin: 0;
  line-height: 30px;
}

.support-center-page .section02 .content .text-wrap ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.support-center-page .section02 .content .text-wrap .p2 li {
  display: flex;
  gap: 10px;
}

.support-center-page .section02 .content .text-wrap {
  max-width: 770px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.support-center-page .section02 .content .img-wrap01 {
  width: 685px;
  height: 480px;
  align-items: center;
  display: flex;
  margin-right: 40px;
}

.support-center-page .section02 .content .img-wrap01 img {
  height: 460px;
}

.support-center-page .section02 .content .img-wrap02 img {
  width: 440px;
  height: 720px;
}

.support-center-page .section02 .content .img-wrap03 {
  width: 534px;
  height: 665px;
  align-items: center;
  display: flex;
}

.support-center-page .section02 .content .img-wrap03 img {
  height: 650px;
  width: 600px;
}

.support-center-page .section02 .content .img-wrap04 {
  width: 510px;
  height: 470px;
  align-items: center;
  display: flex;
}

.support-center-page .section02 .content .img-wrap04 img {
  height: 470px;
  width: 580px;
}

.support-center-page .section02 .content .img-wrap img {
  width: 100%;
  height: auto;
}

.support-center-page .section03 {
  margin: 5% 20%;
}

.support-center-page .section03 img {
  width: 100%;
  height: auto;
}

.support-center-page .search-list-section {
  margin: 5% 10% 10% 10%;
}

.support-center-page .search-list-section .search-list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}

.support-center-page .search-list-section .lawyer {
  flex: 0 1 18%;
}

.support-center-page .search-list-section .lawyer img {
  width: 100%;
  background-color: #f9f9f9;
}

.support-center-page .search-list-section .lawyer .text-wrap {
  text-align: left;
}

.support-center-page .search-list-section .lawyer .text-wrap p {
  margin: 0px;
}

.support-center-page .search-list-section .lawyer .text-wrap .name {
  font-size: 1.5em;
}

.support-center-page .search-list-section .lawyer .text-wrap .name > span {
  font-size: 0.7em;
  color: #888;
  padding-left: 10px;
}

.support-center-page .search-list-section .lawyer .text-wrap .job {
  color: #888;
  margin: 10px 0;
}

.support-center-page .search-list-section .lawyer .text-wrap .phone > span {
  font-weight: bold;
  padding-right: 10px;
}

.support-center-page .search-list-section .lawyer .text-wrap .email > span {
  font-weight: bold;
  padding-right: 10px;
}

.support-center-page .search-list-section .pagination {
  text-align: center;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.support-center-page .search-list-section .page-number {
  margin: 0 5px;
  cursor: pointer;
  color: #555;
}

.support-center-page .search-list-section .page-number.active {
  font-weight: bold;
  color: #007bff;
}

.support-center-page .search-list-section .separator {
  margin: 0 5px;
  color: #bbb;
}

.support-center-page .section04 {
  margin: 125px 260px;
}

.support-center-page .section04 .members-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
  justify-items: center;
  gap: 150px 100px;
}

.support-center-page .section04 .members-wrap .lawyer-item img {
  max-width: 186px;
}

.support-center-page .section04 .members-wrap .lawyer-item .text-wrap {
  position: absolute;
  text-align: left;
  height: 246px;
}

.support-center-page .section04 .members-wrap .lawyer-item .text-wrap p {
  margin: 5px 0;
}

.support-center-page .section04 .members-wrap .lawyer-item .text-wrap .p1 {
  font-size: 28px;
  display: inline-block;
}

.support-center-page
  .section04
  .members-wrap
  .lawyer-item
  .text-wrap
  .p1
  span:last-child {
  color: #666666;
}

.support-center-page .section04 .members-wrap .lawyer-item .text-wrap .p2 {
  color: #999;
  font-size: 16px;
  display: inline-block;
  margin-left: 10px;
}

.support-center-page .section04 .members-wrap .lawyer-item .class-wrap {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 190px;
}

.support-center-page .section04 .members-wrap .lawyer-item .text-wrap .p3 {
  border: 1px solid #999999;
  color: #666666;
  font-size: 14px;
  width: fit-content;
  padding: 2px 4px;
  margin: 0;
}

.support-center-page .section04 .toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 120px auto;
  color: #999999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 16px;
  position: relative;
  width: 100%;
  font-weight: bold;
}

.support-center-page .section04 .toggle-button img {
  width: 20px;
  margin-left: 10px;
}

.support-center-page .section04 .toggle-button::before,
.support-center-page .section04 .toggle-button::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 1px;
  background-color: #e0e0e0;
}

.support-center-page .section04 .toggle-button::before {
  left: 0;
}

.support-center-page .section04 .toggle-button::after {
  right: 0;
}

.support-center-page .section05 {
  margin: 0px 300px 60px 300px;
}

.support-center-page .section05 .sub-title {
  text-align: left;
}

.support-center-page .section05 .sub-title p {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
}

.support-center-page .section05 .sub-title button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 5px;
  margin: 10px;
}

.support-center-page .section05 .sub-title button img {
  width: 50px;
  margin-left: 10px;
}

.support-center-page .section05 .swiper-wrapper {
  padding-bottom: 24px;
}

.support-center-page .section05 .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

.support-center-page .section05 .swiper-container {
  padding: 0;
  margin: 28px auto;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1232px;
}

.support-center-page .section05 .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.support-center-page .section05 .nav-button.prev {
  left: -80px;
}

.support-center-page .section05 .nav-button.next {
  right: -80px;
}

.support-center-page .section05 .newsletter-content {
  box-sizing: border-box;
  border: 3px solid #d9d9d9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 301px;
  min-width: 160px;
  min-height: 125px;
  max-height: 230px;
}

.support-center-page .section05 .date {
  letter-spacing: 0.1px;
}

.support-center-page .section05 .newsletter-content .mark {
  text-align: left;
  height: 34px;
  align-items: center;
  display: flex;
}

.support-center-page .section05 .newsletter-content .mark span {
  background-color: #1676bc;
  color: white;
  padding: 8px;
  font-size: 1em;
  align-items: center;
  height: 1em;
  font-weight: bold;
}

.support-center-page .section05 .newsletter-content .title {
  height: 200px;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  margin: 20px 0px;
  line-height: 20.8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.support-center-page .section05 .newsletter-content .bottom {
  height: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #999;
  align-items: center;
  margin-top: auto;
}

.support-center-page .section05 .newsletter-content .bottom a {
  text-decoration: none;
  color: #888;
}

.support-center-page .section05 .newsletter-content .bottom .more {
  text-decoration: underline;
  cursor: pointer;
}

.support-center-page .section05 .swiper-scrollbar-drag {
  background: #1676bc;
}

.support-center-page .section05 .swiper-scrollbar {
  position: static;
  width: 100%;
}

.support-center-page .section05 .newsletter-content:hover {
  border: 3px solid #1676bc;
}

/* 센터페이지 기술지원센터 */

.tech-center-page {
  position: relative;
  text-align: center;
  padding-top: 72px;
  letter-spacing: -0.15em;
}

.tech-center-page .main {
  position: relative;
    height: 892px;
    background:
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255) 100%
    ),
    url("../src/assets/images/CenterMain01.png");
    background-size: cover;
    background-position: center;
}

.tech-center-page .main .img-wrap {
  position: relative;
  width: 100%;
  height: auto;
}

.tech-center-page .main .img-wrap img {
  width: 100%;
  height: auto;
}

.tech-center-page .main .text-wrap {
  position: absolute;
  top: 60%;
  left: 40%;
  transform: translate(-50%, -50%);
  text-align: left;
  z-index: 100;
}

.tech-center-page .main .text-wrap p {
  font-size: 60px;
  font-weight: bold;
}

.tech-center-page .main .text-wrap h1 {
  font-size: 100px;
  font-weight: bold;
  margin: 0;
}

.tech-center-page .theme-select {
  position: sticky;
  top: 72px;
  background-color: white;
  z-index: 100;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tech-center-page .theme-select button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 5px;
  margin: 10px;
}

.tech-center-page .theme-select button.active {
  font-weight: bold;
  color: black;
}

.tech-center-page .theme-select button.active::after {
  border-bottom: 4px solid black;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.tech-center-page .theme-select button:hover {
  color: black;
  font-weight: bold;
}

.tech-center-page > .section01 {
  position: relative;
}

.tech-center-page > .section01 .img-wrap {
  z-index: 1;
  margin: 0 10%;
}

.tech-center-page > .section01 img {
  width: 100%;
  max-width: 600px;
  height: auto;
  text-align: left;
}

.tech-center-page > .section01 .text-wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}

.tech-center-page > .section01 .text-wrap .p1 {
  font-weight: bold;
  font-size: 1.2em;
}

.tech-center-page > .section01 .text-wrap h1 {
  font-size: 40px;
  margin: 6px 0 48px 0;
}

.tech-center-page > .section01 .text-wrap .p2 {
  font-size: 20px;
  line-height: 40px;
}

.tech-center-page > .sub-title {
  width: 100%;
  font-size: 9em;
  color: #eeeeee;
  font-weight: normal;
  text-align: center;
}

.tech-center-page .section02 {
  margin: 0 260px;
}

.tech-center-page .section02 .content {
  display: flex;
  margin: 130px auto 230px auto;
}

.tech-center-page .section02 .content .text-wrap01 {
  flex: 3;
  text-align: left;
  margin-top: auto;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tech-center-page .section02 .content .text-wrap01 .p1 {
  font-weight: bold;
  font-size: 35px;
  margin: 0px 0px 40px 0px;
  line-height: 1.5;
}

.tech-center-page .section02 .content .text-wrap01 .p2 {
  font-size: 20px;
  margin: 0;
  line-height: 30px;
}

.tech-center-page .section02 .content .text-wrap02 {
  flex: 3;
  text-align: left;
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tech-center-page .section02 .content .text-wrap02 .p1 {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 27px;
  line-height: 1.5;
}

.tech-center-page .section02 .content .text-wrap02 .p2 {
  font-size: 20px;
  margin: 0;
  line-height: 30px;
}

.tech-center-page .section02 .content .img-wrap01 {
  flex: 3;
  align-items: center;
  display: flex;
}

.tech-center-page .section02 .content .img-wrap01 img {
  width: 639px;
  height: 177px;
}

.tech-center-page .section02 .content .img-wrap02 {
  flex: 3;
  align-items: center;
  display: flex;
}

.tech-center-page .section02 .content .img-wrap02 img {
  max-width: 613px;
  height: 356px;
}

.tech-center-page .section03 {
  margin: 5% 20%;
}

.tech-center-page .section03 img {
  width: 100%;
  height: auto;
}

.tech-center-page .search-list-section {
  margin: 5% 10% 10% 10%;
}

.tech-center-page .search-list-section .search-list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}

.tech-center-page .search-list-section .lawyer {
  flex: 0 1 18%;
}

.tech-center-page .search-list-section .lawyer img {
  width: 100%;
  background-color: #f9f9f9;
}

.tech-center-page .search-list-section .lawyer .text-wrap {
  text-align: left;
}

.tech-center-page .search-list-section .lawyer .text-wrap p {
  margin: 0px;
}

.tech-center-page .search-list-section .lawyer .text-wrap .name {
  font-size: 1.5em;
}

.tech-center-page .search-list-section .lawyer .text-wrap .name > span {
  font-size: 0.7em;
  color: #888;
  padding-left: 10px;
}

.tech-center-page .search-list-section .lawyer .text-wrap .job {
  color: #888;
  margin: 10px 0;
}

.tech-center-page .search-list-section .lawyer .text-wrap .phone > span {
  font-weight: bold;
  padding-right: 10px;
}

.tech-center-page .search-list-section .lawyer .text-wrap .email > span {
  font-weight: bold;
  padding-right: 10px;
}

.tech-center-page .search-list-section .pagination {
  text-align: center;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech-center-page .search-list-section .page-number {
  margin: 0 5px;
  cursor: pointer;
  color: #555;
}

.tech-center-page .search-list-section .page-number.active {
  font-weight: bold;
  color: #007bff;
}

.tech-center-page .search-list-section .separator {
  margin: 0 5px;
  color: #bbb;
}

.tech-center-page .section04 {
  padding: 125px 260px;
}

.tech-center-page .section04 .members-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 150px 100px;
}

.tech-center-page .section04 .members-wrap .lawyer-item img {
  width: 100%;
  max-width: 186px;
}

.tech-center-page .section04 .members-wrap .lawyer-item .text-wrap {
  position: absolute;
  text-align: left;
}

.tech-center-page .section04 .members-wrap .lawyer-item .text-wrap p {
  margin: 5px 0;
}

.tech-center-page .section04 .members-wrap .lawyer-item .text-wrap .p1 {
  font-size: 28px;
  color: #333333;
  display: inline-block;
}

.tech-center-page .section04 .members-wrap .lawyer-item .text-wrap .p2 {
  color: #999999;
  font-size: 16px;
  display: inline-block;
  margin-left: 10px;
}

.tech-center-page .section04 .members-wrap .lawyer-item .text-wrap .class-wrap {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 190px;
}

.tech-center-page .section04 .members-wrap .lawyer-item .text-wrap .p3 {
  border: 1px solid #999999;
  color: #666666;
  font-size: 14px;
  width: fit-content;
  padding: 2px 4px;
  margin: 0;
}

.tech-center-page .section04 .toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 120px auto;
  color: #999999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: relative;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
}

.tech-center-page .section04 .toggle-button img {
  width: 20px;
  margin-left: 10px;
}

.tech-center-page .section04 .toggle-button::before,
.tech-center-page .section04 .toggle-button::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 1px;
  background-color: #e0e0e0;
}

.tech-center-page .section04 .toggle-button::before {
  left: 0;
}

.tech-center-page .section04 .toggle-button::after {
  right: 0;
}

.tech-center-page .section05 {
  margin: 0px 300px 60px 300px;
}

.tech-center-page .section05 .sub-title {
  text-align: left;
}

.tech-center-page .section05 .sub-title p {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
}

.tech-center-page .section05 .sub-title button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 5px;
  margin: 10px;
}

.tech-center-page .section05 .sub-title button img {
  width: 50px;
  margin-left: 10px;
}

.tech-center-page .section05 .swiper-wrapper {
  padding-bottom: 24px;
}

.tech-center-page .section05 .swiper-container {
  padding: 0;
  margin: 28px auto;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1232px;
}

.tech-center-page .section05 .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.tech-center-page .section05 .nav-button.prev {
  left: -80px;
}

.tech-center-page .section05 .nav-button.next {
  right: -80px;
}

.tech-center-page .section05 .newsletter-content {
  box-sizing: border-box;
  border: 3px solid #d9d9d9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 301px;
  min-width: 160px;
  min-height: 125px;
  max-height: 230px;
}

.tech-center-page .section05 .date {
  letter-spacing: 0.1px;
}

.tech-center-page .section05 .newsletter-content .mark {
  text-align: left;
  height: 34px;
  align-items: center;
  display: flex;
}

.tech-center-page .section05 .newsletter-content .mark span {
  background-color: #1676bc;
  color: white;
  padding: 8px;
  font-size: 1em;
  align-items: center;
  height: 1em;
  font-weight: bold;
}

.tech-center-page .section05 .newsletter-content .title {
  height: 200px;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  margin: 20px 0px;
  line-height: 20.8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tech-center-page .section05 .newsletter-content .bottom {
  height: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #999;
  align-items: center;
  margin-top: auto;
  margin-left: 0px;
}

.tech-center-page .section05 .newsletter-content .bottom a {
  text-decoration: none;
  color: #888;
}

.tech-center-page .section05 .newsletter-content .bottom .more {
  text-decoration: underline;
  cursor: pointer;
}

.tech-center-page .section05 .swiper-scrollbar-drag {
  background: #1676bc;
}

.tech-center-page .section05 .swiper-scrollbar {
  position: static;
  width: 100%;
}

.tech-center-page .section05 .newsletter-content:hover {
  border: 3px solid #1676bc;
}

/* 건설/부동산 센터 */

.property-center-page {
  position: relative;
  text-align: center;
  padding-top: 72px;
  letter-spacing: -0.15em;
}

.property-center-page .main {
  position: relative;
  height: 892px;
  background:
  linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255) 100%
  ),
  url("../src/assets/images/CenterMain01.png");
  background-size: cover;
  background-position: center;
}

.property-center-page .main .img-wrap {
  position: relative;
  width: 100%;
  height: auto;
}

.property-center-page .main .img-wrap img {
  width: 100%;
  height: auto;
}

.property-center-page .main .text-wrap {
  position: absolute;
  top: 60%;
  left: 35%;
  transform: translate(-50%, -50%);
  text-align: left;
  z-index: 100;
}

.property-center-page .main .text-wrap p {
  font-size: 60px;
  font-weight: bold;
}

.property-center-page .main .text-wrap h1 {
  font-size: 100px;
  font-weight: bold;
  margin: 0;
}

.property-center-page .theme-select {
  position: sticky;
  top: 72px;
  background-color: white;
  z-index: 100;
  display: flex;
  justify-content: center;
  margin: 20px 0px 300px 0px;
}

.property-center-page .theme-select button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 5px;
  margin: 10px;
}

.property-center-page .theme-select button.active {
  font-weight: bold;
  color: black;
}

.property-center-page .theme-select button.active::after {
  border-bottom: 4px solid black;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.property-center-page .theme-select button:hover {
  color: black;
  font-weight: bold;
}

.property-center-page .strength-section {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: auto;
  gap: 20px;
}

.property-center-page .strength-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 423px;
  height: 400px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-top: 3px solid #1676bc;
  margin-top: 50px;
  background-color: white;
  z-index: 1;
}

.property-center-page .strength-card h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.01em;
}

.property-center-page .strength-card h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.01em;
}

.property-center-page > .section01 {
  position: relative;
  max-height: 550px;
}

.property-center-page > .section01 .img-wrap {
  position: relative;
  bottom: 200px;
  max-height: 230px;
}

.property-center-page > .section01 img {
  position: relative;
  width: 100%;
  max-width: 789px;
  height: auto;
  text-align: left;
  right: 280px;
  bottom: 510px;
}

.property-center-page > .section01 .text-wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}

.property-center-page > .section01 .text-wrap .p1 {
  font-weight: bold;
  font-size: 1.2em;
}

.property-center-page > .section01 .text-wrap h1 {
  font-size: 40px;
  margin: 6px 0 48px 0;
}

.property-center-page > .section01 .text-wrap .p2 {
  font-size: 20px;
  line-height: 40px;
}

.property-center-page > .sub-title {
  width: 100%;
  font-size: 9em;
  color: #eeeeee;
  font-weight: normal;
  text-align: center;
}

.property-center-page .section02 {
  padding: 0 260px;
}

.property-center-page .section02 .content {
  display: flex;
  margin: 50px auto 100px auto;
  align-items: flex-start;
}

.property-center-page .section02 .content .text-wrap {
  flex: 3;
  text-align: left;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.property-center-page .section02 .content .text-wrap .h3 {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 30px;
  line-height: 130%;
  letter-spacing: -0.01em;
}

.property-center-page .section02 .content .text-wrap .p {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.01em;
}

.property-center-page .section02 .content .img-wrap01 img {
  width: 850px;
  height: 430px;
}

.property-center-page .section02 .content .img-wrap02 img {
  width: 788px;
  height: 504px;
}

.property-center-page .section02 .content .img-wrap03 img {
  width: 659px;
  height: 271px;
}

.property-center-page .section02 .content .img-wrap04 img {
  width: 1400px;
  height: 262px;
}

.property-center-page .section02 .content h3 {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 30px;
  line-height: 130%;
  letter-spacing: -0.01em;
}

.property-center-page .section02 .content .text-direction {
  display: flex;
  gap: 190px;
}

.property-center-page .section02 .content .text-ul {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;
  left: 40px;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.01em;
  min-width: 470px;
  max-width: 503px;
}

.property-center-page .section02 .content .text-ul li::before {
  content: "•";
  position: absolute;
  left: -20px;
}

.property-center-page .section03 img {
  width: 100%;
  height: auto;
}

.property-center-page .search-list-section {
  margin: 5% 10% 10% 10%;
}

.property-center-page .search-list-section .search-list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}

.property-center-page .search-list-section .lawyer {
  flex: 0 1 18%;
}

.property-center-page .search-list-section .lawyer img {
  width: 100%;
  background-color: #f9f9f9;
}

.property-center-page .search-list-section .lawyer .text-wrap {
  text-align: left;
}

.property-center-page .search-list-section .lawyer .text-wrap p {
  margin: 0px;
}

.property-center-page .search-list-section .lawyer .text-wrap .name {
  font-size: 1.5em;
}

.property-center-page .search-list-section .lawyer .text-wrap .name > span {
  font-size: 0.7em;
  color: #888;
  padding-left: 10px;
}

.property-center-page .search-list-section .lawyer .text-wrap .job {
  color: #888;
  margin: 10px 0;
}

.property-center-page .search-list-section .lawyer .text-wrap .phone > span {
  font-weight: bold;
  padding-right: 10px;
}

.property-center-page .search-list-section .lawyer .text-wrap .email > span {
  font-weight: bold;
  padding-right: 10px;
}

.property-center-page .search-list-section .pagination {
  text-align: center;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.property-center-page .search-list-section .page-number {
  margin: 0 5px;
  cursor: pointer;
  color: #555;
}

.property-center-page .search-list-section .page-number.active {
  font-weight: bold;
  color: #007bff;
}

.property-center-page .search-list-section .separator {
  margin: 0 5px;
  color: #bbb;
}

.property-center-page .section04 {
  padding: 125px 260px;
}

.property-center-page .section04 .members-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 150px 100px;
}

.property-center-page .section04 .members-wrap .lawyer-item img {
  width: 100%;
  max-width: 186px;
}

.property-center-page .section04 .members-wrap .lawyer-item .text-wrap {
  position: absolute;
  text-align: left;
}

.property-center-page .section04 .members-wrap .lawyer-item .text-wrap p {
  margin: 5px 0;
}

.property-center-page .section04 .members-wrap .lawyer-item .text-wrap .p1 {
  font-size: 28px;
  color: #333333;
  display: inline-block;
}

.property-center-page .section04 .members-wrap .lawyer-item .text-wrap .p2 {
  color: #999999;
  font-size: 16px;
  display: inline-block;
  margin-left: 10px;
}

.property-center-page
  .section04
  .members-wrap
  .lawyer-item
  .text-wrap
  .class-wrap {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 190px;
}

.property-center-page .section04 .members-wrap .lawyer-item .text-wrap .p3 {
  border: 1px solid #999999;
  color: #666666;
  font-size: 14px;
  width: fit-content;
  padding: 2px 4px;
  margin: 0;
}

.property-center-page .section04 .toggle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 120px auto;
  color: #999999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: relative;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
}

.property-center-page .section04 .toggle-button img {
  width: 20px;
  margin-left: 10px;
}

.property-center-page .section04 .toggle-button::before,
.property-center-page .section04 .toggle-button::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 1px;
  background-color: #e0e0e0;
}

.property-center-page .section04 .toggle-button::before {
  left: 0;
}

.property-center-page .section04 .toggle-button::after {
  right: 0;
}

.property-center-page .section05 {
  margin: 0px 300px 60px 300px;
}

.property-center-page .section05 .sub-title {
  text-align: left;
}

.property-center-page .section05 .sub-title p {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
}

.property-center-page .section05 .sub-title button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  color: #888;
  position: relative;
  padding: 5px;
  margin: 10px;
}

.property-center-page .section05 .sub-title button img {
  width: 50px;
  margin-left: 10px;
}

.property-center-page .section05 .swiper-wrapper {
  padding-bottom: 24px;
}

.property-center-page .section05 .swiper-container {
  padding: 0;
  margin: 28px auto;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1232px;
}

.property-center-page .section05 .nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: #1676bc;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.property-center-page .section05 .nav-button.prev {
  left: -80px;
}

.property-center-page .section05 .nav-button.next {
  right: -80px;
}

.property-center-page .section05 .newsletter-content {
  box-sizing: border-box;
  border: 3px solid #d9d9d9;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 301px;
  min-width: 160px;
  min-height: 125px;
  max-height: 230px;
}

.property-center-page .section05 .date {
  letter-spacing: 0.1px;
}

.property-center-page .section05 .newsletter-content .mark {
  text-align: left;
  height: 34px;
  align-items: center;
  display: flex;
}

.property-center-page .section05 .newsletter-content .mark span {
  background-color: #1676bc;
  color: white;
  padding: 8px;
  font-size: 1em;
  align-items: center;
  height: 1em;
  font-weight: bold;
}

.property-center-page .section05 .newsletter-content .title {
  height: 200px;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  margin: 20px 0px;
  line-height: 20.8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.property-center-page .section05 .newsletter-content .bottom {
  height: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #999;
  align-items: center;
  margin-top: auto;
  margin-left: 0px;
}

.property-center-page .section05 .newsletter-content .bottom a {
  text-decoration: none;
  color: #888;
}

.property-center-page .section05 .newsletter-content .bottom .more {
  text-decoration: underline;
  cursor: pointer;
}

.property-center-page .section05 .swiper-scrollbar-drag {
  background: #1676bc;
}

.property-center-page .section05 .swiper-scrollbar {
  position: static;
  width: 100%;
}

.property-center-page .section05 .newsletter-content:hover {
  border: 3px solid #1676bc;
}
